import React from "react";
import { Navigate, useLocation, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { checkReferralLink } from "./helpers/helpers";

const PrivateRoute = () => {
  const referralLink = localStorage.getItem("ref");
  const compaign_name = localStorage.getItem("compaign_name");
  const authToken = useSelector((state) => state.fetchSignin.authToken);
  let location = useLocation();
  return authToken ? (
    <Outlet />
  ) : (
    <Navigate
      to={
        checkReferralLink(referralLink)
          ? `/signin?ref=${referralLink}&compaign_name=${compaign_name}`
          : `/signin`
      }
      replace
      state={{ from: location }}
    />
  );
};

export default PrivateRoute;
