import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import FormProcessingSpinner from "../common/FormProcessingSpinner";
import ReportPopup from "./ReportPopup";
import { reportAnJobPopup } from "../../states/actions/fetchJobs";

function JobApplyPopup({
  job,
  dismissPopup,
  handleJobApply,
  isJobApplying,
  saveJob,
  isJobSaving,
  isJobReporting,
  handleReportAnJob,
  handleShowSigninPopup,
  showSigninPopup,
  showReportPopup,
  handleToggleReportPopup,
}) {
  // const dispatch = useDispatch();
  const authToken = useSelector((state) => state.fetchSignin.authToken);
  // const showReportPopup = useSelector(
  //   (state) => state.fetchJobs.showReportPopup
  // );

  // const handleToggleReportPopup = () => {
  //   dispatch(reportAnJobPopup());
  // };

  return (
    <>
      <section className="popup sm" style={{ display: "block" }}>
        <div className="table_dv">
          <div className="table_cell">
            <div className="contain">
              <div className="_inner apply_job_pop_up">
                <button
                  type="button"
                  className="x_btn"
                  onClick={dismissPopup}
                ></button>
                <div className="act_btn btn_blk abt_act_btn">
                  {job.saved ? (
                    <a type="button" className="site_btn blank sm active">
                      <img
                        src={
                          process.env.PUBLIC_URL + "/images/icon-bookmark.svg"
                        }
                        alt=""
                      />
                      {/* <span>Saved</span> */}
                    </a>
                  ) : (
                    <>
                      <a
                        type="button"
                        onClick={() => saveJob({ id: job.id })}
                        disabled={isJobSaving}
                        className="site_btn blank sm"
                      >
                        <img
                          src={
                            process.env.PUBLIC_URL + "/images/icon-bookmark.svg"
                          }
                          alt=""
                        />
                        {/* <span>Save this job</span> */}
                      </a>
                      {/* <p>Save this job</p> */}
                    </>
                  )}
                </div>
                <div className="head_pop_title">
                  <h5>{job.title}</h5>
                </div>
                <div className="apply_job_cntnt">
                  <div className="form_row row">
                    <div className="col-sm-12">
                      {job.applied ? (
                        <p>You have applied for this job.</p>
                      ) : (
                        <>
                          <p>Did you apply for this job ?</p>
                          <div className="btn_blk half_btn_blk">
                            <button
                              type="button"
                              className="site_btn"
                              onClick={() => handleJobApply(job.id)}
                            >
                              Yes
                            </button>
                            {/* <br /> */}
                            <button
                              type="button"
                              className="site_btn"
                              onClick={dismissPopup}
                              disabled={isJobApplying}
                            >
                              <FormProcessingSpinner
                                isFormProccessing={isJobApplying}
                              />
                              No
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                  <div className="act_btn btn_blk text-right report_act_btn">
                    <a
                      type="button"
                      onClick={handleToggleReportPopup}
                      className="site_btn blank sm"
                    >
                      <img
                        src={process.env.PUBLIC_URL + "/images/flag-altt.svg"}
                        alt=""
                      />
                      <span>Report An Issue</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {showReportPopup && (
        <ReportPopup
          handleToggleReportPopup={handleToggleReportPopup}
          job={job}
          isJobReporting={isJobReporting}
          handleReportAnJob={handleReportAnJob}
        />
      )}
    </>
  );
}

export default JobApplyPopup;
