import React, { useEffect } from "react";
import Data from "../../dummy";
import SubscriptionPlan from "./SubscriptionPlan";
import FaqList from "./FaqList";

import { fetchSubscription } from "../../../states/actions/fetchSubscription";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";
import { useSearchParams } from "react-router-dom";
import useReferralLink from "../../../hooks/useReferralLink";

const Subscription = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const referralLink = useReferralLink(
    searchParams.get("ref"),
    searchParams.get("compaign_name")
  );
  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchSubscription.content);
  const isLoading = useSelector((state) => state.fetchSubscription.isLoading);
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  const { content, faqs, offers, plans, subscribed_id } = data;

  useEffect(() => {
    dispatch(fetchSubscription({ ref: referralLink }));
  }, []);

  useDocumentTitle(data.page_title);
  return (
    <>
      {isLoading || isSiteSettingsLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <SubscriptionPlan
            data={Data.subscription}
            content={content}
            offers={offers}
            plans={plans}
            subscribed_id={subscribed_id}
            subscRef={referralLink}
          />
          <FaqList content={content} faqs={faqs} />
        </>
      )}
    </>
  );
};

export default Subscription;
