import React, { useEffect } from "react";
import Data from "../../dummy";
import Register from "./Register";
import { ToastContainer } from "react-toastify";

import {
  createAccount,
  fetchSignup
} from "../../../states/actions/fetchSignup";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";
import { useSearchParams } from "react-router-dom";

const Signup = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchSignup.content);
  const isLoading = useSelector((state) => state.fetchSignup.isLoading);
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  const isFormProcessing = useSelector(
    (state) => state.fetchSignup.isFormProcessing
  );
  const { content } = data;

  // useEffect(() => {
  //   console.log(isFormProcessing);
  // }, [isFormProcessing]);

  useEffect(() => {
    dispatch(fetchSignup({ ref: searchParams.get("ref") }));
  }, []);

  const handleSubmitAction = (formData) => {
    dispatch(createAccount(formData));
  };

  useDocumentTitle(data.page_title);
  return (
    <>
      {isLoading || isSiteSettingsLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <ToastContainer />
          <Register
            data={Data.signup}
            content={content}
            handleSubmitAction={handleSubmitAction}
            isFormProcessing={isFormProcessing}
            signupRef={searchParams.get("ref")}
          />
        </>
      )}
    </>
  );
};

export default Signup;
