const Data = {
	srch_bar: {
		icon: "/images/icon-search.svg",
		alt: "Search status icon",
		btn: "Search Now",
		flag: "/images/flag_uk.svg",
		flag_alt: "UK",
	},
	home: {
		banner: {
			tag: "Career Path",
			heading: "Careers guidance for International Students in ",
			heading_ex: "united kingdom.",
			para: "Find jobs, employment & career opportunities",
			user: {
				title: "20K + Candidates",
				ul: [
					{
						id: 1,
						src: "/images/users/1.jpg",
						alt: "User Icon",
					},
					{
						id: 2,
						src: "/images/users/2.jpg",
						alt: "User Icon",
					},
					{
						id: 3,
						src: "/images/users/3.jpg",
						alt: "User Icon",
					},
					{
						id: 4,
						src: "/images/users/4.jpg",
						alt: "User Icon",
					},
					{
						id: 5,
						src: "/images/users/5.jpg",
						alt: "User Icon",
					},
					{
						id: 6,
						src: "/images/users/6.jpg",
						alt: "User Icon",
					},
				],
			},
			video: "/videos/Office - 69952.mp4",
		},
		brands: {
			icons: [
				{
					id: 1,
					src: "/images/brands/1.png",
					alt: "Brand_01",
				},
				{
					id: 2,
					src: "/images/brands/2.png",
					alt: "Brand_02",
				},
				{
					id: 3,
					src: "/images/brands/3.png",
					alt: "Brand_03",
				},
				{
					id: 4,
					src: "/images/brands/4.png",
					alt: "Brand_04",
				},
				{
					id: 5,
					src: "/images/brands/5.png",
					alt: "Brand_05",
				},
				{
					id: 6,
					src: "/images/brands/6.png",
					alt: "Brand_06",
				},
			],
		},
		steps: {
			heading: "Manage Your Job Application in 4 Steps",
			para: "Content section built to demonstrate the process flow aspect of your product.",
			block: [
				{
					id: 1,
					num: "1",
					title: "Explore",
					para: "Career opportunities available for you to apply now.",
				},
				{
					id: 2,
					num: "2",
					title: "Learn",
					para: "About what recruiters look for in the UK",
				},
				{
					id: 3,
					num: "3",
					title: "Practice",
					para: "Online tests, Interviews & Assessment Centres",
				},
				{
					id: 4,
					num: "4",
					title: "Apply & Track",
					para: "Your application and ask us for support",
				},
			],
		},
		intro: {
			heading: "We Support Your  Journey With Robust Applications",
			block: [
				{
					id: 1,
					src: "/images/image_01.png",
					alt: "Feature Image",
					tag: "Features",
					title: "Find Your Potential Career Path",
					title_ex: "With A Quiz",
					para: "If you are going to use a passage of Lorem Ipsum, you need to be sure there isn’t anything embarrassing hidden in the middle of text ll the of us Lorem Ipsum. If you are going to use a passage of Lorem Ipsum ere isn’t anything embarrassing hidden",
				},
				{
					id: 2,
					src: "/images/image_02.png",
					alt: "Feature Image",
					tag: "Features",
					title: "Create an job-focused",
					title_ex: "Outstanding CV",
					para: "If you are going to use a passage of Lorem Ipsum, you need to be sure there isn’t anything embarrassing hidden in the middle of text ll the of us Lorem Ipsum. If you are going to use a passage of Lorem Ipsum, you as need to be sure there isn’t anything embarrassing.",
				},
				{
					id: 3,
					src: "/images/image_03.png",
					alt: "Feature Image",
					tag: "Features",
					title: "Practice On-demand",
					title_ex: "job Interview",
					para: "If you are going to use a passage of Lorem Ipsum, you need to be sure there isn’t anything embarrassing hidden in the middle of text ll the of us Lorem Ipsum. If you are going to use a passage of Lorem Ipsum",
				},
				{
					id: 4,
					src: "/images/image_04.png",
					alt: "Feature Image",
					tag: "Features",
					title: "Stay on Top of Your",
					title_ex: "Application",
					para: "If you are going to use a passage of Lorem Ipsum, you need to be sure there isn’t anything embarrassing hidden in the middle of text ll the of us Lorem Ipsum. If you are going to use a passage of Lorem Ipsum, you as need to be sure there isn’t anything embarrassing.",
				},
			],
		},
		journey: {
			heading: "We Support Your  Journey With Robust Applications",
			block: [
				{
					id: 1,
					qst: "Career path Finder",
					ans: "Back in 2016, our founders Tripti Maheshwari and Dhruv  experienced the challenges of landing opportunities in a foreign land as international  visitor students.",
				},
				{
					id: 2,
					qst: "CV Builder",
					ans: "Back in 2016, our founders Tripti Maheshwari and Dhruv  experienced the challenges of landing opportunities in a foreign land as international  visitor students.",
				},
				{
					id: 3,
					qst: "Digital Interview Practice",
					ans: "Back in 2016, our founders Tripti Maheshwari and Dhruv  experienced the challenges of landing opportunities in a foreign land as international  visitor students.",
				},
				{
					id: 4,
					qst: "Application Tracker",
					ans: "Back in 2016, our founders Tripti Maheshwari and Dhruv  experienced the challenges of landing opportunities in a foreign land as international  visitor students.",
				},
			],
			slider: [
				{
					id: 1,
					title: "Find Your Potential Career Path With A Quiz",
					para: "If you are going to use a passage of Lorem Ipsum, you need to be sure there isn’t anything embarrassing hidden.",
				},
				{
					id: 2,
					title: "Create an job-focused Outstanding CV",
					para: "If you are going to use a passage of Lorem Ipsum, you need to be sure there isn’t anything embarrassing hidden.",
				},
				{
					id: 3,
					title: "Practice On-demand job Interview",
					para: "If you are going to use a passage of Lorem Ipsum, you need to be sure there isn’t anything embarrassing hidden.",
				},
				{
					id: 4,
					title: "Stay on Top of Your Application",
					para: "If you are going to use a passage of Lorem Ipsum, you need to be sure there isn’t anything embarrassing hidden.",
				},
			],
			thumbs: [
				{
					id: 1,
					src: "/images/Rectangle 1464.jpg",
					alt: "Photo",
				},
				{
					id: 2,
					src: "/images/Rectangle 1464 (2).jpg",
					alt: "Photo",
				},
				{
					id: 3,
					src: "/images/Rectangle 1465.jpg",
					alt: "Photo",
				},
				{
					id: 4,
					src: "/images/Rectangle 1466.jpg",
					alt: "Photo",
				},
			],
		},
		categories: {
			tag: "Categories",
			heading: "Top Sectors that Hire International Students",
			block: [
				{
					id: 1,
					title: "Business Development",
					src: "/images/icon-briefcase.svg",
					alt: "Briefcase Icon",
				},
				{
					id: 2,
					title: "Sciences & Engineering",
					src: "/images/icon-desktop.svg",
					alt: "Desktop Icon",
				},
				{
					id: 3,
					title: "Business Law",
					src: "/images/icon-judge.svg",
					alt: "Judge Icon",
				},
				{
					id: 4,
					title: "Design, Plan & Creatives",
					src: "/images/icon-creative.svg",
					alt: "Creative Icon",
				},
				{
					id: 5,
					title: "languages",
					src: "/images/icon-language.svg",
					alt: "Language Icon",
				},
				{
					id: 6,
					title: "Videos & VFX",
					src: "/images/icon-video.svg",
					alt: "Video Icon",
				},
				{
					id: 7,
					title: "Others Vacancies",
					src: "/images/icon-receipt.svg",
					alt: "Receipt Icon",
				},
				{
					id: 8,
					title: "Financial Services",
					src: "/images/icon-wallet.svg",
					alt: "Wallet Icon",
				},
			],
		},
		sponsor: {
			heading: "Top Visa Sponsoring Employers in UK",
			icons: [
				{
					id: 1,
					src: "/images/brands/1.png",
					alt: "Brand_01",
				},
				{
					id: 2,
					src: "/images/brands/2.png",
					alt: "Brand_02",
				},
				{
					id: 3,
					src: "/images/brands/3.png",
					alt: "Brand_03",
				},
				{
					id: 4,
					src: "/images/brands/4.png",
					alt: "Brand_04",
				},
				{
					id: 5,
					src: "/images/brands/5.png",
					alt: "Brand_05",
				},
				{
					id: 6,
					src: "/images/brands/6.png",
					alt: "Brand_06",
				},
			],
		},
		folio: {
			heading: "Here From Our Past Candidates",
			block: [
				{
					id: 1,
					src: "/images/01.jpg",
					alt: "Emila morgan",
					name: "Emila morgan",
					label: "University of Hertfordshire",
					para: "I came for my Masters to the UK in January 2018. I had already accepted a campus placement back in India after my Bachelors, but I was keen on higher education so I that's how I am here. The first semester was on till May, thereafter I joined a summer internship at a company for 3 months. ",
					btn: "Read More",
				},
				{
					id: 2,
					src: "/images/02.jpg",
					alt: "Kyle Mills",
					name: "Kyle Mills",
					label: "University of Hertfordshire",
					para: "I came for my Masters to the UK in January 2018. I had already accepted a campus placement back in India after my Bachelors, but I was keen on higher education so I that's how I am here. The first semester was on till May, thereafter I joined a summer internship at a company for 3 months. ",
					btn: "Read More",
				},
				{
					id: 3,
					src: "/images/03.jpg",
					alt: "Chris Ewans",
					name: "Chris Ewans",
					label: "University of Hertfordshire",
					para: "I came for my Masters to the UK in January 2018. I had already accepted a campus placement back in India after my Bachelors, but I was keen on higher education so I that's how I am here. The first semester was on till May, thereafter I joined a summer internship at a company for 3 months. ",
					btn: "Read More",
				},
				{
					id: 4,
					src: "/images/04.jpg",
					alt: "John Wick",
					name: "John Wick",
					label: "University of Hertfordshire",
					para: "I came for my Masters to the UK in January 2018. I had already accepted a campus placement back in India after my Bachelors, but I was keen on higher education so I that's how I am here. The first semester was on till May, thereafter I joined a summer internship at a company for 3 months. ",
					btn: "Read More",
				},
			],
		},
	},
	about: {
		page_intro: {
			heading: "We are changing the ",
			heading_ex: "whole game.",
			para: "Gravida donec sit quam lacinia et sed et. Lectus in pellentesque sed a eget mauris. Tincidunt tincidunt sit quisque orci turpis nulla velit.",
			src: "/images/Rectangle 1464.jpg",
			alt: "About us",
		},
		work: {
			src: "/images/Rectangle 1465.jpg",
			alt: "Work Image",
			tag: "Work with us",
			title: "Creating for both side win-win situation",
			title_ex: "",
			para: "Back in 2016, our founders Tripti Maheshwari and Dhruv Krishnaraj experienced the challenges of landing opportunities in a foreign land as international students. They realised fairly soon that the process isn’t easy for anyone involved, so they started Student Circus, a niche job search platform, with the vision to make the hiring of international students a seamless process for all stakeholders — universities, students, as well as employers. Since then, Student Circus has bloomed from being a job listings platform to a all career needs of international students.",
			btn: "Contact us now",
			btn_link: "/contact",
		},
		vision: {
			tag: "our vision",
			heading: "The values that hold us true and to account",
			block: [
				{
					id: 1,
					src: "/images/icon-teacher.svg",
					alt: "Graduation Icon",
					title: "Fairness & equity",
					para: "The colour of our passport should elevate the talent and skills of the candidates, not stifle them.",
				},
				{
					id: 2,
					src: "/images/icon-briefcase2.svg",
					alt: "Briefcase Icon",
					title: "Value for time",
					para: "Time is the most very important commodity for all now. Our service aims to save time for everyone.",
				},
				{
					id: 3,
					src: "/images/icon-book.svg",
					alt: "Book Icon",
					title: "Simplifying careers",
					para: "We want everyone to have access to all opportunities for growth. Gatekeeping serves no one.",
				},
				{
					id: 4,
					src: "/images/icon-monitor-mobile.svg",
					alt: "Monitor Icon",
					title: "Informed decisions",
					para: "Knowledge is power. We bring relevant information to help all stakeholders with efficient decision-making.",
				},
			],
		},
		faq: {
			tag: "CATEGORIES",
			heading: "Frequently Asked Questions",
			block: [
				{
					id: 1,
					qst: "Build to industry leading standards visa process?",
					ans: "Back in 2016, our founders Tripti Maheshwari and Dhruv  experienced the challenges of landing opportunities in a foreign land as international  visitor students. They realised fairly soon that the process isn’t easy for anyone involved, so they started Student Circus, a niche job search platform, with the vision to make the hiring of international students a seamless process for all stakeholders — universities, students, as well as employers. Since then, we can Student Circus has bloomed the a from being a job listings platform to a all career needs of international students.  Since then, we can Student Circus has bloomed from being a  career needs of international students job listings platform to a all career needs of international students.",
				},
				{
					id: 2,
					qst: "What kind of service we offer to you?",
					ans: "Back in 2016, our founders Tripti Maheshwari and Dhruv  experienced the challenges of landing opportunities in a foreign land as international  visitor students. They realised fairly soon that the process isn’t easy for anyone involved, so they started Student Circus, a niche job search platform, with the vision to make the hiring of international students a seamless process for all stakeholders — universities, students, as well as employers. Since then, we can Student Circus has bloomed the a from being a job listings platform to a all career needs of international students.  Since then, we can Student Circus has bloomed from being a  career needs of international students job listings platform to a all career needs of international students.",
				},
				{
					id: 3,
					qst: "We're helping everyone build better sites?",
					ans: "Back in 2016, our founders Tripti Maheshwari and Dhruv  experienced the challenges of landing opportunities in a foreign land as international  visitor students. They realised fairly soon that the process isn’t easy for anyone involved, so they started Student Circus, a niche job search platform, with the vision to make the hiring of international students a seamless process for all stakeholders — universities, students, as well as employers. Since then, we can Student Circus has bloomed the a from being a job listings platform to a all career needs of international students.  Since then, we can Student Circus has bloomed from being a  career needs of international students job listings platform to a all career needs of international students.",
				},
				{
					id: 4,
					qst: "Our team of expert visa jobs and interview marketers?",
					ans: "Back in 2016, our founders Tripti Maheshwari and Dhruv  experienced the challenges of landing opportunities in a foreign land as international  visitor students. They realised fairly soon that the process isn’t easy for anyone involved, so they started Student Circus, a niche job search platform, with the vision to make the hiring of international students a seamless process for all stakeholders — universities, students, as well as employers. Since then, we can Student Circus has bloomed the a from being a job listings platform to a all career needs of international students.  Since then, we can Student Circus has bloomed from being a  career needs of international students job listings platform to a all career needs of international students.",
				},
				{
					id: 5,
					qst: "Build a website that actually performs better?",
					ans: "Back in 2016, our founders Tripti Maheshwari and Dhruv  experienced the challenges of landing opportunities in a foreign land as international  visitor students. They realised fairly soon that the process isn’t easy for anyone involved, so they started Student Circus, a niche job search platform, with the vision to make the hiring of international students a seamless process for all stakeholders — universities, students, as well as employers. Since then, we can Student Circus has bloomed the a from being a job listings platform to a all career needs of international students.  Since then, we can Student Circus has bloomed from being a  career needs of international students job listings platform to a all career needs of international students.",
				},
			],
		},
		contact: {
			tag: "Contact us",
			heading: "Connect with our best support team to grow your business",
			para: "Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus.",
			info_list: [
				{
					id: 1,
					icon: "/images/icon-location.svg",
					alt: "Location",
					text: "Kemp House, 152 - 160 City Road, London, EC1V 2NX",
					// link: "",
				},
				{
					id: 2,
					icon: "/images/icon-sms-tracking.svg",
					alt: "Envelope",
					text: "support@ukvisajobs.com",
					link: "mailto:support@ukvisajobs.com",
				},
				{
					id: 3,
					icon: "/images/icon-call-incoming.svg",
					alt: "Phone",
					text: "0112-345-345-5544",
					link: "tel:0112-345-345-5544",
				},
			],
			form: {
				btn: "Send Now",
			},
		},
	},
	work: {
		page_intro: {
			heading: "Connect with the best Global ",
			heading_ex: "Millennials",
			para: "Gravida donec sit quam lacinia et sed et. Lectus in pellentesque sed a eget mauris. Tincidunt tincidunt sit quisque orci turpis nulla velit.",
			src: "/images/Rectangle 1464 (2).jpg",
			alt: "Work with us",
		},
		vision: {
			tag: "categories",
			heading: "Services aligned to the international student community",
			block: [
				{
					id: 1,
					src: "/images/icon-teacher.svg",
					alt: "Graduation Icon",
					title: "Trusted by universities and students",
					para: "The colour of our passport should elevate the talent and skills of the candidates, not stifle them.",
				},
				{
					id: 2,
					src: "/images/icon-briefcase2.svg",
					alt: "Briefcase Icon",
					title: "Get access to a global  multicultural audience ",
					para: "Time is the most very important commodity for all now. Our service aims to save time for everyone.",
				},
				{
					id: 3,
					src: "/images/icon-book.svg",
					alt: "Book Icon",
					title: "Drive awarness and  consideration",
					para: "We want everyone to have access to all opportunities for growth. Gatekeeping serves no one.",
				},
				{
					id: 4,
					src: "/images/icon-monitor-mobile.svg",
					alt: "Monitor Icon",
					title: "providing the best services for students",
					para: "Knowledge is power. We bring relevant information to help all stakeholders with efficient decision-making.",
				},
			],
		},
		categories: {
			tag: "Categories",
			heading: "Services aligned to the international student community",
			block: [
				{
					id: 1,
					title: "Business Development",
					src: "/images/icon-briefcase.svg",
					alt: "Briefcase Icon",
				},
				{
					id: 2,
					title: "Sciences & Engineering",
					src: "/images/icon-desktop.svg",
					alt: "Desktop Icon",
				},
				{
					id: 3,
					title: "Business Law",
					src: "/images/icon-judge.svg",
					alt: "Judge Icon",
				},
				{
					id: 4,
					title: "Design, Plan & Creatives",
					src: "/images/icon-creative.svg",
					alt: "Creative Icon",
				},
				{
					id: 5,
					title: "languages",
					src: "/images/icon-language.svg",
					alt: "Language Icon",
				},
				{
					id: 6,
					title: "Videos & VFX",
					src: "/images/icon-video.svg",
					alt: "Video Icon",
				},
				{
					id: 7,
					title: "Others Vacancies",
					src: "/images/icon-receipt.svg",
					alt: "Receipt Icon",
				},
				{
					id: 8,
					title: "Financial Services",
					src: "/images/icon-wallet.svg",
					alt: "Wallet Icon",
				},
			],
		},
		companies: {
			tag: "Companies",
			heading: "Here from our partner companies",
			block: [
				{
					id: 1,
					src: "/images/fedex.png",
					alt: "Fedex Logo",
					title: "University of Hertfordshire",
					para: "Get all the top tips about how to apply for a working visa after your studies in the UK from our friends at Student Circus.",
				},
				{
					id: 2,
					src: "/images/fedex.png",
					alt: "Fedex Logo",
					title: "University of Hertfordshire",
					para: "Get all the top tips about how to apply for a working visa after your studies in the UK from our friends at Student Circus.",
				},
				{
					id: 3,
					src: "/images/fedex.png",
					alt: "Fedex Logo",
					title: "University of Hertfordshire",
					para: "Get all the top tips about how to apply for a working visa after your studies in the UK from our friends at Student Circus.",
				},
				{
					id: 4,
					src: "/images/fedex.png",
					alt: "Fedex Logo",
					title: "University of Hertfordshire",
					para: "Get all the top tips about how to apply for a working visa after your studies in the UK from our friends at Student Circus.",
				},
				{
					id: 5,
					src: "/images/fedex.png",
					alt: "Fedex Logo",
					title: "University of Hertfordshire",
					para: "Get all the top tips about how to apply for a working visa after your studies in the UK from our friends at Student Circus.",
				},
				{
					id: 6,
					src: "/images/fedex.png",
					alt: "Fedex Logo",
					title: "University of Hertfordshire",
					para: "Get all the top tips about how to apply for a working visa after your studies in the UK from our friends at Student Circus.",
				},
			],
		},
		strip: {
			heading: "Interested in partnering?",
			para: "We'd love to collaborate. Let's discuss the details!",
			btn: "Contact us now",
			btn_link: "/contact",
		},
		faq: {
			tag: "CATEGORIES",
			heading: "Frequently Asked Questions",
			block: [
				{
					id: 1,
					qst: "Build to industry leading standards visa process?",
					ans: "Back in 2016, our founders Tripti Maheshwari and Dhruv  experienced the challenges of landing opportunities in a foreign land as international  visitor students. They realised fairly soon that the process isn’t easy for anyone involved, so they started Student Circus, a niche job search platform, with the vision to make the hiring of international students a seamless process for all stakeholders — universities, students, as well as employers. Since then, we can Student Circus has bloomed the a from being a job listings platform to a all career needs of international students.  Since then, we can Student Circus has bloomed from being a  career needs of international students job listings platform to a all career needs of international students.",
				},
				{
					id: 2,
					qst: "What kind of service we offer to you?",
					ans: "Back in 2016, our founders Tripti Maheshwari and Dhruv  experienced the challenges of landing opportunities in a foreign land as international  visitor students. They realised fairly soon that the process isn’t easy for anyone involved, so they started Student Circus, a niche job search platform, with the vision to make the hiring of international students a seamless process for all stakeholders — universities, students, as well as employers. Since then, we can Student Circus has bloomed the a from being a job listings platform to a all career needs of international students.  Since then, we can Student Circus has bloomed from being a  career needs of international students job listings platform to a all career needs of international students.",
				},
				{
					id: 3,
					qst: "We're helping everyone build better sites?",
					ans: "Back in 2016, our founders Tripti Maheshwari and Dhruv  experienced the challenges of landing opportunities in a foreign land as international  visitor students. They realised fairly soon that the process isn’t easy for anyone involved, so they started Student Circus, a niche job search platform, with the vision to make the hiring of international students a seamless process for all stakeholders — universities, students, as well as employers. Since then, we can Student Circus has bloomed the a from being a job listings platform to a all career needs of international students.  Since then, we can Student Circus has bloomed from being a  career needs of international students job listings platform to a all career needs of international students.",
				},
				{
					id: 4,
					qst: "Our team of expert visa jobs and interview marketers?",
					ans: "Back in 2016, our founders Tripti Maheshwari and Dhruv  experienced the challenges of landing opportunities in a foreign land as international  visitor students. They realised fairly soon that the process isn’t easy for anyone involved, so they started Student Circus, a niche job search platform, with the vision to make the hiring of international students a seamless process for all stakeholders — universities, students, as well as employers. Since then, we can Student Circus has bloomed the a from being a job listings platform to a all career needs of international students.  Since then, we can Student Circus has bloomed from being a  career needs of international students job listings platform to a all career needs of international students.",
				},
				{
					id: 5,
					qst: "Build a website that actually performs better?",
					ans: "Back in 2016, our founders Tripti Maheshwari and Dhruv  experienced the challenges of landing opportunities in a foreign land as international  visitor students. They realised fairly soon that the process isn’t easy for anyone involved, so they started Student Circus, a niche job search platform, with the vision to make the hiring of international students a seamless process for all stakeholders — universities, students, as well as employers. Since then, we can Student Circus has bloomed the a from being a job listings platform to a all career needs of international students.  Since then, we can Student Circus has bloomed from being a  career needs of international students job listings platform to a all career needs of international students.",
				},
			],
		},
	},
	partner: {
		page_intro: {
			heading: "Connect with the best Global ",
			heading_ex: "Millennials",
			para: "Gravida donec sit quam lacinia et sed et. Lectus in pellentesque sed a eget mauris. Tincidunt tincidunt sit quisque orci turpis nulla velit.",
			src: "/images/Rectangle 1464 (2).jpg",
			alt: "Work with us",
		},
		vision: {
			tag: "categories",
			heading: "Services aligned to the international student community",
			block: [
				{
					id: 1,
					src: "/images/icon-teacher.svg",
					alt: "Graduation Icon",
					title: "Trusted by universities and students",
					para: "The colour of our passport should elevate the talent and skills of the candidates, not stifle them.",
				},
				{
					id: 2,
					src: "/images/icon-briefcase2.svg",
					alt: "Briefcase Icon",
					title: "Get access to a global  multicultural audience ",
					para: "Time is the most very important commodity for all now. Our service aims to save time for everyone.",
				},
				{
					id: 3,
					src: "/images/icon-book.svg",
					alt: "Book Icon",
					title: "Drive awarness and  consideration",
					para: "We want everyone to have access to all opportunities for growth. Gatekeeping serves no one.",
				},
				{
					id: 4,
					src: "/images/icon-monitor-mobile.svg",
					alt: "Monitor Icon",
					title: "providing the best services for students",
					para: "Knowledge is power. We bring relevant information to help all stakeholders with efficient decision-making.",
				},
			],
		},
		categories: {
			tag: "Categories",
			heading: "Services aligned to the international student community",
			block: [
				{
					id: 1,
					title: "Business Development",
					src: "/images/icon-briefcase.svg",
					alt: "Briefcase Icon",
				},
				{
					id: 2,
					title: "Sciences & Engineering",
					src: "/images/icon-desktop.svg",
					alt: "Desktop Icon",
				},
				{
					id: 3,
					title: "Business Law",
					src: "/images/icon-judge.svg",
					alt: "Judge Icon",
				},
				{
					id: 4,
					title: "Design, Plan & Creatives",
					src: "/images/icon-creative.svg",
					alt: "Creative Icon",
				},
				{
					id: 5,
					title: "languages",
					src: "/images/icon-language.svg",
					alt: "Language Icon",
				},
				{
					id: 6,
					title: "Videos & VFX",
					src: "/images/icon-video.svg",
					alt: "Video Icon",
				},
				{
					id: 7,
					title: "Others Vacancies",
					src: "/images/icon-receipt.svg",
					alt: "Receipt Icon",
				},
				{
					id: 8,
					title: "Financial Services",
					src: "/images/icon-wallet.svg",
					alt: "Wallet Icon",
				},
			],
		},
		companies: {
			tag: "Companies",
			heading: "Here from our partner companies",
			block: [
				{
					id: 1,
					src: "/images/fedex.png",
					alt: "Fedex Logo",
					title: "University of Hertfordshire",
					para: "Get all the top tips about how to apply for a working visa after your studies in the UK from our friends at Student Circus.",
				},
				{
					id: 2,
					src: "/images/fedex.png",
					alt: "Fedex Logo",
					title: "University of Hertfordshire",
					para: "Get all the top tips about how to apply for a working visa after your studies in the UK from our friends at Student Circus.",
				},
				{
					id: 3,
					src: "/images/fedex.png",
					alt: "Fedex Logo",
					title: "University of Hertfordshire",
					para: "Get all the top tips about how to apply for a working visa after your studies in the UK from our friends at Student Circus.",
				},
				{
					id: 4,
					src: "/images/fedex.png",
					alt: "Fedex Logo",
					title: "University of Hertfordshire",
					para: "Get all the top tips about how to apply for a working visa after your studies in the UK from our friends at Student Circus.",
				},
				{
					id: 5,
					src: "/images/fedex.png",
					alt: "Fedex Logo",
					title: "University of Hertfordshire",
					para: "Get all the top tips about how to apply for a working visa after your studies in the UK from our friends at Student Circus.",
				},
				{
					id: 6,
					src: "/images/fedex.png",
					alt: "Fedex Logo",
					title: "University of Hertfordshire",
					para: "Get all the top tips about how to apply for a working visa after your studies in the UK from our friends at Student Circus.",
				},
			],
		},
		strip: {
			heading: "Interested in partnering?",
			para: "We'd love to collaborate. Let's discuss the details!",
			btn: "Contact us now",
			btn_link: "/contact",
		},
		faq: {
			tag: "CATEGORIES",
			heading: "Frequently Asked Questions",
			block: [
				{
					id: 1,
					qst: "Build to industry leading standards visa process?",
					ans: "Back in 2016, our founders Tripti Maheshwari and Dhruv  experienced the challenges of landing opportunities in a foreign land as international  visitor students. They realised fairly soon that the process isn’t easy for anyone involved, so they started Student Circus, a niche job search platform, with the vision to make the hiring of international students a seamless process for all stakeholders — universities, students, as well as employers. Since then, we can Student Circus has bloomed the a from being a job listings platform to a all career needs of international students.  Since then, we can Student Circus has bloomed from being a  career needs of international students job listings platform to a all career needs of international students.",
				},
				{
					id: 2,
					qst: "What kind of service we offer to you?",
					ans: "Back in 2016, our founders Tripti Maheshwari and Dhruv  experienced the challenges of landing opportunities in a foreign land as international  visitor students. They realised fairly soon that the process isn’t easy for anyone involved, so they started Student Circus, a niche job search platform, with the vision to make the hiring of international students a seamless process for all stakeholders — universities, students, as well as employers. Since then, we can Student Circus has bloomed the a from being a job listings platform to a all career needs of international students.  Since then, we can Student Circus has bloomed from being a  career needs of international students job listings platform to a all career needs of international students.",
				},
				{
					id: 3,
					qst: "We're helping everyone build better sites?",
					ans: "Back in 2016, our founders Tripti Maheshwari and Dhruv  experienced the challenges of landing opportunities in a foreign land as international  visitor students. They realised fairly soon that the process isn’t easy for anyone involved, so they started Student Circus, a niche job search platform, with the vision to make the hiring of international students a seamless process for all stakeholders — universities, students, as well as employers. Since then, we can Student Circus has bloomed the a from being a job listings platform to a all career needs of international students.  Since then, we can Student Circus has bloomed from being a  career needs of international students job listings platform to a all career needs of international students.",
				},
				{
					id: 4,
					qst: "Our team of expert visa jobs and interview marketers?",
					ans: "Back in 2016, our founders Tripti Maheshwari and Dhruv  experienced the challenges of landing opportunities in a foreign land as international  visitor students. They realised fairly soon that the process isn’t easy for anyone involved, so they started Student Circus, a niche job search platform, with the vision to make the hiring of international students a seamless process for all stakeholders — universities, students, as well as employers. Since then, we can Student Circus has bloomed the a from being a job listings platform to a all career needs of international students.  Since then, we can Student Circus has bloomed from being a  career needs of international students job listings platform to a all career needs of international students.",
				},
				{
					id: 5,
					qst: "Build a website that actually performs better?",
					ans: "Back in 2016, our founders Tripti Maheshwari and Dhruv  experienced the challenges of landing opportunities in a foreign land as international  visitor students. They realised fairly soon that the process isn’t easy for anyone involved, so they started Student Circus, a niche job search platform, with the vision to make the hiring of international students a seamless process for all stakeholders — universities, students, as well as employers. Since then, we can Student Circus has bloomed the a from being a job listings platform to a all career needs of international students.  Since then, we can Student Circus has bloomed from being a  career needs of international students job listings platform to a all career needs of international students.",
				},
			],
		},
	},
	careers: {
		career_path: {
			heading: "preparing to your Career ",
			heading_ex: "path test.",
			para: "Discover careers that match your skills and personality Worker Visa Jobs in the UK, and Jobs in Home Countries for students returning back home after graduation.",
			src: "/images/Rectangle 1466.jpg",
			alt: "Work with us",
			btn: "Start My Test",
			btn_link: "/signup",
		},
		vision: {
			tag: "categories",
			heading: "Discover careers that match your skills",
			block: [
				{
					id: 1,
					src: "/images/icon-clipboard-text.svg",
					alt: "Clipboard Icon",
					title: "24 questions",
					para: "Answer 24 questions about your working style and your future career preferences as for the order.",
				},
				{
					id: 2,
					src: "/images/icon-timer-start.svg",
					alt: "Timer Icon",
					title: "2 minutes",
					para: "Gain insights into your future career in just two minutes.International talent from across the globe.",
				},
				{
					id: 3,
					src: "/images/icon-book.svg",
					alt: "Book Icon",
					title: "Personalised advice",
					para: "We go above & beyond to deliver  & Receive personalised advice guide you on your next steps.",
				},
				{
					id: 4,
					src: "/images/icon-clipboard-export.svg",
					alt: "Clipboard Icon",
					title: "exam preperation",
					para: "We go above & beyond to deliver  & Receive personalised advice guide you on your next steps.",
				},
			],
		},
		work_analys: {
			src: "/images/Rectangle 1465 (2).jpg",
			alt: "Work Image",
			tag: "Work with us",
			heading: "We've analysed data from thousands of ",
			heading_ex: "our members ",
			para: "We've analysed data from thousands of our members who work in graduate roles across a range of sectors to understand which personalities, skills and values best fit each career path. Take this test to understand what career path you might be suited to and how to get started.",
			btn: "Contact us now",
			btn_link: "/contact",
			block: [
				{
					id: 1,
					title: "Business Development",
					src: "/images/icon-briefcase.svg",
					alt: "Briefcase Icon",
				},
				{
					id: 2,
					title: "Sciences & Engineering",
					src: "/images/icon-desktop.svg",
					alt: "Desktop Icon",
				},
				{
					id: 3,
					title: "Business Law",
					src: "/images/icon-judge.svg",
					alt: "Judge Icon",
				},
				{
					id: 4,
					title: "Design, Plan & Creatives",
					src: "/images/icon-creative.svg",
					alt: "Creative Icon",
				},
			],
		},
		strip: {
			heading: "start your test now!",
			para: "We'd love to collaborate. Let's discuss the details!",
			btn: "Get started",
			btn_link: "/contact",
		},
	},
	open_jobs: {
		heading: "Search Jobs",
		show_result: "Showing 1-30 of 33,013 search results.",
		jobs: [
			{
				id: 1,
				src: "/images/companies/01.png",
				alt: "Surge",
				title: "Senior Full-Stack Software Engineer - React / Node.js",
				link: "/job-detail",
				company: "Envision Technologies Inc.",
				company_link: "https://www.google.com/",
				location: "United Kingdom",
				degree: "Degree in IT",
				salary: "100,000-120,000 / Yearly",
				act_btn: {
					view: "/job-detail",
					bookmark: "/job-detail",
				},
			},
			{
				id: 2,
				src: "/images/companies/02.png",
				alt: "ABA",
				title: "Full-time Content Creator",
				para: "Soluta corrupti saepe porro rerum et nihil maiores adipisci quisquam architecto iste deserunt aut, quas ex explicabo corporis iure. Totam, itaque facere!",
				link: "/job-detail",
				company: "Envision Technologies Inc.",
				company_link: "https://www.google.com/",
				location: "United Kingdom",
				degree: "Degree in IT",
				salary: "100,000-120,000 / Yearly",
				act_btn: {
					view: "/job-detail",
					bookmark: "/job-detail",
				},
			},
			{
				id: 3,
				src: "/images/companies/03.png",
				alt: "Asterik",
				title: "Senior Technical Specialist - Service Now",
				para: "Soluta corrupti saepe porro rerum et nihil maiores adipisci quisquam architecto iste deserunt aut, quas ex explicabo corporis iure. Totam, itaque facere!",
				link: "/job-detail",
				company: "Envision Technologies Inc.",
				company_link: "https://www.google.com/",
				location: "United Kingdom",
				degree: "Degree in IT",
				salary: "100,000-120,000 / Yearly",
				act_btn: {
					view: "/job-detail",
					bookmark: "/job-detail",
				},
			},
			{
				id: 4,
				src: "/images/companies/04.png",
				alt: "Cuku",
				title: "Trainee Project Coordinator - Part time",
				para: "Soluta corrupti saepe porro rerum et nihil maiores adipisci quisquam architecto iste deserunt aut, quas ex explicabo corporis iure. Totam, itaque facere!",
				link: "/job-detail",
				company: "Envision Technologies Inc.",
				company_link: "https://www.google.com/",
				location: "United Kingdom",
				degree: "Degree in IT",
				salary: "100,000-120,000 / Yearly",
				act_btn: {
					view: "/job-detail",
					bookmark: "/job-detail",
				},
			},
			{
				id: 5,
				src: "/images/companies/05.png",
				alt: "OKTA",
				title: "Software Engineer / Senior Software Engineer",
				para: "Soluta corrupti saepe porro rerum et nihil maiores adipisci quisquam architecto iste deserunt aut, quas ex explicabo corporis iure. Totam, itaque facere!",
				link: "/job-detail",
				company: "Envision Technologies Inc.",
				company_link: "https://www.google.com/",
				location: "United Kingdom",
				degree: "Degree in IT",
				salary: "100,000-120,000 / Yearly",
				act_btn: {
					view: "/job-detail",
					bookmark: "/job-detail",
				},
			},
			{
				id: 6,
				src: "/images/companies/01.png",
				alt: "Surge",
				title: "Senior Full-Stack Software Engineer - React / Node.js",
				para: "Soluta corrupti saepe porro rerum et nihil maiores adipisci quisquam architecto iste deserunt aut, quas ex explicabo corporis iure. Totam, itaque facere!",
				link: "/job-detail",
				company: "Envision Technologies Inc.",
				company_link: "https://www.google.com/",
				location: "United Kingdom",
				degree: "Degree in IT",
				salary: "100,000-120,000 / Yearly",
				act_btn: {
					view: "/job-detail",
					bookmark: "/job-detail",
				},
			},
			{
				id: 7,
				src: "/images/companies/02.png",
				alt: "ABA",
				title: "Full-time Content Creator",
				para: "Soluta corrupti saepe porro rerum et nihil maiores adipisci quisquam architecto iste deserunt aut, quas ex explicabo corporis iure. Totam, itaque facere!",
				link: "/job-detail",
				company: "Envision Technologies Inc.",
				company_link: "https://www.google.com/",
				location: "United Kingdom",
				degree: "Degree in IT",
				salary: "100,000-120,000 / Yearly",
				act_btn: {
					view: "/job-detail",
					bookmark: "/job-detail",
				},
			},
			{
				id: 8,
				src: "/images/companies/03.png",
				alt: "Asterik",
				title: "Senior Technical Specialist - Service Now",
				para: "Soluta corrupti saepe porro rerum et nihil maiores adipisci quisquam architecto iste deserunt aut, quas ex explicabo corporis iure. Totam, itaque facere!",
				link: "/job-detail",
				company: "Envision Technologies Inc.",
				company_link: "https://www.google.com/",
				location: "United Kingdom",
				degree: "Degree in IT",
				salary: "100,000-120,000 / Yearly",
				act_btn: {
					view: "/job-detail",
					bookmark: "/job-detail",
				},
			},
			{
				id: 9,
				src: "/images/companies/04.png",
				alt: "Cuku",
				title: "Trainee Project Coordinator - Part time",
				para: "Soluta corrupti saepe porro rerum et nihil maiores adipisci quisquam architecto iste deserunt aut, quas ex explicabo corporis iure. Totam, itaque facere!",
				link: "/job-detail",
				company: "Envision Technologies Inc.",
				company_link: "https://www.google.com/",
				location: "United Kingdom",
				degree: "Degree in IT",
				salary: "100,000-120,000 / Yearly",
				act_btn: {
					view: "/job-detail",
					bookmark: "/job-detail",
				},
			},
			{
				id: 10,
				src: "/images/companies/05.png",
				alt: "OKTA",
				title: "Software Engineer / Senior Software Engineer",
				para: "Soluta corrupti saepe porro rerum et nihil maiores adipisci quisquam architecto iste deserunt aut, quas ex explicabo corporis iure. Totam, itaque facere!",
				link: "/job-detail",
				company: "Envision Technologies Inc.",
				company_link: "https://www.google.com/",
				location: "United Kingdom",
				degree: "Degree in IT",
				salary: "100,000-120,000 / Yearly",
				act_btn: {
					view: "/job-detail",
					bookmark: "/job-detail",
				},
			},
		],
	},
	job_profile: {
		intro: {
			heading: "Welcome to your Career ",
			heading_ex: "Path Guidence.",
			para: "Welcome to your one-stop shop for essential career path advice. Whether you're still figuring out which direction you are heading in, or you want to find out how to excel in your chosen industry - explore the articles in this section for sector the insights, insider advice and top tips for building a brilliant growth your career for your future.",
		},
		list: [
			{
				id: 1,
				src: "/images/fig_01.jpg",
				alt: "Profile Photo",
				link: "/profile-detail",
				title: "Accounting, Audit & Tax",
				para: "The pharmaceuticals sector is the scaffolding supporting the medical world, drip-feeding hospitals, pharmacies and patients.",
				btn: "Read More",
			},
			{
				id: 2,
				src: "/images/fig_02.jpg",
				alt: "Profile Photo",
				link: "/profile-detail",
				title: "Asset & Investment Management",
				para: "The pharmaceuticals sector is the scaffolding supporting the medical world, drip-feeding hospitals, pharmacies and patients.",
				btn: "Read More",
			},
			{
				id: 3,
				src: "/images/fig_03.jpg",
				alt: "Profile Photo",
				link: "/profile-detail",
				title: "Commercial Law",
				para: "The pharmaceuticals sector is the scaffolding supporting the medical world, drip-feeding hospitals, pharmacies and patients.",
				btn: "Read More",
			},
			{
				id: 4,
				src: "/images/fig_04.jpg",
				alt: "Profile Photo",
				link: "/profile-detail",
				title: "Consulting",
				para: "The pharmaceuticals sector is the scaffolding supporting the medical world, drip-feeding hospitals, pharmacies and patients.",
				btn: "Read More",
			},
			{
				id: 5,
				src: "/images/fig_05.jpg",
				alt: "Profile Photo",
				link: "/profile-detail",
				title: "Consumer, FMCG & Retail",
				para: "The pharmaceuticals sector is the scaffolding supporting the medical world, drip-feeding hospitals, pharmacies and patients.",
				btn: "Read More",
			},
			{
				id: 6,
				src: "/images/fig_06.jpg",
				alt: "Profile Photo",
				link: "/profile-detail",
				title: "Engineering & construction",
				para: "The pharmaceuticals sector is the scaffolding supporting the medical world, drip-feeding hospitals, pharmacies and patients.",
				btn: "Read More",
			},
			{
				id: 7,
				src: "/images/fig_07.jpg",
				alt: "Profile Photo",
				link: "/profile-detail",
				title: "Entrepreneurship & Start Ups",
				para: "The pharmaceuticals sector is the scaffolding supporting the medical world, drip-feeding hospitals, pharmacies and patients.",
				btn: "Read More",
			},
			{
				id: 8,
				src: "/images/fig_08.jpg",
				alt: "Profile Photo",
				link: "/profile-detail",
				title: "Financial Services",
				para: "The pharmaceuticals sector is the scaffolding supporting the medical world, drip-feeding hospitals, pharmacies and patients.",
				btn: "Read More",
			},
			{
				id: 9,
				src: "/images/fig_09.jpg",
				alt: "Profile Photo",
				link: "/profile-detail",
				title: "Insurance & Risk Management",
				para: "The pharmaceuticals sector is the scaffolding supporting the medical world, drip-feeding hospitals, pharmacies and patients.",
				btn: "Read More",
			},
			{
				id: 10,
				src: "/images/fig_10.jpg",
				alt: "Profile Photo",
				link: "/profile-detail",
				title: "Investment Banking",
				para: "The pharmaceuticals sector is the scaffolding supporting the medical world, drip-feeding hospitals, pharmacies and patients.",
				btn: "Read More",
			},
			{
				id: 11,
				src: "/images/fig_11.jpg",
				alt: "Profile Photo",
				link: "/profile-detail",
				title: "Marketing, media & PR",
				para: "The pharmaceuticals sector is the scaffolding supporting the medical world, drip-feeding hospitals, pharmacies and patients.",
				btn: "Read More",
			},
			{
				id: 12,
				src: "/images/fig_12.jpg",
				alt: "Profile Photo",
				link: "/profile-detail",
				title: "Management & Business",
				para: "The pharmaceuticals sector is the scaffolding supporting the medical world, drip-feeding hospitals, pharmacies and patients.",
				btn: "Read More",
			},
			{
				id: 13,
				src: "/images/fig_13.jpg",
				alt: "Profile Photo",
				link: "/profile-detail",
				title: "Pharmaceuticals",
				para: "The pharmaceuticals sector is the scaffolding supporting the medical world, drip-feeding hospitals, pharmacies and patients.",
				btn: "Read More",
			},
			{
				id: 14,
				src: "/images/fig_14.jpg",
				alt: "Profile Photo",
				link: "/profile-detail",
				title: "Technology & IT infrastructure",
				para: "The pharmaceuticals sector is the scaffolding supporting the medical world, drip-feeding hospitals, pharmacies and patients.",
				btn: "Read More",
			},
			{
				id: 15,
				src: "/images/fig_15.jpg",
				alt: "Profile Photo",
				link: "/profile-detail",
				title: "Sales & Commercial",
				para: "The pharmaceuticals sector is the scaffolding supporting the medical world, drip-feeding hospitals, pharmacies and patients.",
				btn: "Read More",
			},
		],
		categories: {
			block: [
				{
					id: 1,
					title: "Business Development",
					src: "/images/icon-briefcase.svg",
					alt: "Briefcase Icon",
				},
				{
					id: 2,
					title: "Sciences & Engineering",
					src: "/images/icon-desktop.svg",
					alt: "Desktop Icon",
				},
				{
					id: 3,
					title: "Business Law",
					src: "/images/icon-judge.svg",
					alt: "Judge Icon",
				},
				{
					id: 4,
					title: "Design & Creatives",
					src: "/images/icon-creative.svg",
					alt: "Creative Icon",
				},
				{
					id: 5,
					title: "Languages",
					src: "/images/icon-language.svg",
					alt: "Language Icon",
				},
				{
					id: 6,
					title: "Videos & VFX",
					src: "/images/icon-video.svg",
					alt: "Video Icon",
				},
				{
					id: 7,
					title: "Consulting",
					src: "/images/icon-profile-users.svg",
					alt: "Consulting Icon",
				},
				{
					id: 8,
					title: "Financial Services",
					src: "/images/icon-wallet.svg",
					alt: "Wallet Icon",
				},
				{
					id: 9,
					title: "Sales & Commercial",
					src: "/images/icon-briefcase.svg",
					alt: "Briefcase Icon",
				},
				{
					id: 10,
					title: "Technology & IT",
					src: "/images/icon-desktop.svg",
					alt: "Desktop Icon",
				},
				{
					id: 11,
					title: "Marketing, media & PR",
					src: "/images/icon-judge.svg",
					alt: "Judge Icon",
				},
				{
					id: 12,
					title: "Investment Banking",
					src: "/images/icon-creative.svg",
					alt: "Creative Icon",
				},
			],
		},
	},
	booking: {
		heading: "Book your mock assessment centre",
	},
	faq: {
		cover: {
			sec_bg: "/images/email_marketing.jpg",
			heading: "FAQ's",
		},
		accordion: {
			block: [
				{
					id: 1,
					qst: "Build to industry leading standards visa process?",
					ans: "Back in 2016, our founders Tripti Maheshwari and Dhruv  experienced the challenges of landing opportunities in a foreign land as international  visitor students. They realised fairly soon that the process isn’t easy for anyone involved, so they started Student Circus, a niche job search platform, with the vision to make the hiring of international students a seamless process for all stakeholders — universities, students, as well as employers. Since then, we can Student Circus has bloomed the a from being a job listings platform to a all career needs of international students.  Since then, we can Student Circus has bloomed from being a  career needs of international students job listings platform to a all career needs of international students.",
				},
				{
					id: 2,
					qst: "What kind of service we offer to you?",
					ans: "Back in 2016, our founders Tripti Maheshwari and Dhruv  experienced the challenges of landing opportunities in a foreign land as international  visitor students. They realised fairly soon that the process isn’t easy for anyone involved, so they started Student Circus, a niche job search platform, with the vision to make the hiring of international students a seamless process for all stakeholders — universities, students, as well as employers. Since then, we can Student Circus has bloomed the a from being a job listings platform to a all career needs of international students.  Since then, we can Student Circus has bloomed from being a  career needs of international students job listings platform to a all career needs of international students.",
				},
				{
					id: 3,
					qst: "We're helping everyone build better sites?",
					ans: "Back in 2016, our founders Tripti Maheshwari and Dhruv  experienced the challenges of landing opportunities in a foreign land as international  visitor students. They realised fairly soon that the process isn’t easy for anyone involved, so they started Student Circus, a niche job search platform, with the vision to make the hiring of international students a seamless process for all stakeholders — universities, students, as well as employers. Since then, we can Student Circus has bloomed the a from being a job listings platform to a all career needs of international students.  Since then, we can Student Circus has bloomed from being a  career needs of international students job listings platform to a all career needs of international students.",
				},
				{
					id: 4,
					qst: "Our team of expert visa jobs and interview marketers?",
					ans: "Back in 2016, our founders Tripti Maheshwari and Dhruv  experienced the challenges of landing opportunities in a foreign land as international  visitor students. They realised fairly soon that the process isn’t easy for anyone involved, so they started Student Circus, a niche job search platform, with the vision to make the hiring of international students a seamless process for all stakeholders — universities, students, as well as employers. Since then, we can Student Circus has bloomed the a from being a job listings platform to a all career needs of international students.  Since then, we can Student Circus has bloomed from being a  career needs of international students job listings platform to a all career needs of international students.",
				},
				{
					id: 5,
					qst: "Build a website that actually performs better?",
					ans: "Back in 2016, our founders Tripti Maheshwari and Dhruv  experienced the challenges of landing opportunities in a foreign land as international  visitor students. They realised fairly soon that the process isn’t easy for anyone involved, so they started Student Circus, a niche job search platform, with the vision to make the hiring of international students a seamless process for all stakeholders — universities, students, as well as employers. Since then, we can Student Circus has bloomed the a from being a job listings platform to a all career needs of international students.  Since then, we can Student Circus has bloomed from being a  career needs of international students job listings platform to a all career needs of international students.",
				},
			],
		},
	},
	signin: {
		sec_bg: "/images/black_layer.svg",
		side: {
			tag: "connect with us",
			title: "Hello again!",
			para: "Rerum veniam dolores sit illum consectetur dolorum numquam eveniet iste. Pariatur fugiat consequatur nobis dolor et labore sit voluptas omnis.",
			account: "Don’t have an account? ",
			account_link: "/signup",
			account_link_txt: "Create an account",
		},
		log: {
			title: "Sign in",
			para: "Please login to see this page.",
			forget: "Forget password? ",
			forget_link: "/forgot-password",
			forget_link_txt: "Reset now",
		},
	},
	signup: {
		heading: "Register",
		top_bar: [
			{
				num: 1,
				text: "Basic info",
			},
			{
				num: 2,
				text: "Education",
			},
			{
				num: 3,
				text: "Job preference",
			},
		],
	},
	forgot: {
		sec_bg: "/images/black_layer.svg",
		side: {
			tag: "connect with us",
			title: "Hello again!",
			para: "Rerum veniam dolores sit illum consectetur dolorum numquam eveniet iste. Pariatur fugiat consequatur nobis dolor et labore sit voluptas omnis.",
			account: "Don’t have an account? ",
			account_link: "/signup",
			account_link_txt: "Create an account",
		},
		log: {
			title: "Reset your password",
			para: "Enter a new password and then repeat it.",
			forget: "Already have an account? ",
			forget_link: "/signin",
			forget_link_txt: "Login",
		},
	},
	events: {
		heading: "Events",
		block: [
			{
				id: 1,
				title: "Virtual Event With Ticket Sales",
				date: "20",
				day: "Fri",
				time: "April 29 @ 5:30 am - 7:30 am",
				para: "This is where you’d put the event description. This is an example of an event using Event Tickets Plus and the Virtual Events add-on where tickets are sold, and guests cannot see the livestream info until they’ve purchased a ticket. Once they’ve purchased a ticket, an email with the livestream link will be automatically",
				link: "/event-detail",
				type: "Virtual Event",
				btn: "Register Now",
				src: "/images/Rectangle 1464 (2).jpg",
				alt: "Event Photo",
			},
			{
				id: 2,
				title: "Virtual Event With Ticket Sales",
				date: "20",
				day: "Fri",
				time: "April 29 @ 5:30 am - 7:30 am",
				para: "This is where you’d put the event description. This is an example of an event using Event Tickets Plus and the Virtual Events add-on where tickets are sold, and guests cannot see the livestream info until they’ve purchased a ticket. Once they’ve purchased a ticket, an email with the livestream link will be automatically",
				link: "/event-detail",
				type: "Virtual Event",
				btn: "Register Now",
				src: "/images/Rectangle 1464.jpg",
				alt: "Event Photo",
			},
			{
				id: 3,
				title: "Virtual Event With Ticket Sales",
				date: "20",
				day: "Fri",
				time: "April 29 @ 5:30 am - 7:30 am",
				para: "This is where you’d put the event description. This is an example of an event using Event Tickets Plus and the Virtual Events add-on where tickets are sold, and guests cannot see the livestream info until they’ve purchased a ticket. Once they’ve purchased a ticket, an email with the livestream link will be automatically",
				link: "/event-detail",
				type: "Virtual Event",
				btn: "Register Now",
				src: "/images/Rectangle 1466.jpg",
				alt: "Event Photo",
			},
			{
				id: 4,
				title: "Virtual Event With Ticket Sales",
				date: "20",
				day: "Fri",
				time: "April 29 @ 5:30 am - 7:30 am",
				para: "This is where you’d put the event description. This is an example of an event using Event Tickets Plus and the Virtual Events add-on where tickets are sold, and guests cannot see the livestream info until they’ve purchased a ticket. Once they’ve purchased a ticket, an email with the livestream link will be automatically",
				link: "/event-detail",
				type: "Virtual Event",
				btn: "Register Now",
				src: "/images/Rectangle 1465.jpg",
				alt: "Event Photo",
			},
		],
	},
	event_detail: {
		heading: "Event Detail",
		detail: {
			title: "Virtual Event With Ticket Sales",
			time: "April 29 @ 5:30 am - 7:30 am",
			type: "Virtual Event",
			price: "$15.00",
			src: "/images/image 27.jpg",
			alt: "Event Photo",
		},
		reg_blk: {
			title: "Register Now",
			subtitle: "lorem ipsum sit amet",
			para: "You will not be charged while using the demo",
			price: "$15.00",
			price_limit: "Unlimited",
			btn: "Register",
			btn_link: "/register",
		},
	},
	interview: {
		heading: "Video Interview",
		top_bar: [
			{
				num: 1,
				text: "Setup",
			},
			{
				num: 2,
				text: "Question 1",
			},
			{
				num: 3,
				text: "Question 2",
			},
			{
				num: 4,
				text: "Question 3",
			},
			{
				num: 5,
				text: "Question 4",
			},
			{
				num: 6,
				text: "Upload/Finish",
			},
		],
		video: "/videos/Office - 69952.mp4",
	},
	privacy: {
		cover: {
			sec_bg: "/images/email_marketing.jpg",
			heading: "Privacy Policy",
		},
	},
	disclaim: {
		cover: {
			sec_bg: "/images/email_marketing.jpg",
			heading: "Disclaimer",
		},
	},
	terms: {
		cover: {
			sec_bg: "/images/email_marketing.jpg",
			heading: "Terms & Conditions",
		},
	},
	dashboard: {
		heading_01: "Application Overview",
		card: [
			{
				id: 1,
				num: "450",
				text: "Job Applied",
			},
			{
				id: 2,
				num: "1500",
				text: "online test complted",
			},
			{
				id: 3,
				num: "175",
				text: "Interview attened",
			},
			{
				id: 4,
				num: "938",
				text: "assement center attended",
			},
			{
				id: 5,
				num: "780",
				text: "Offer received",
			},
			{
				id: 6,
				num: "80%",
				text: "rate of passing intial stage",
			},
			{
				id: 7,
				num: "72%",
				text: "test pass rate",
			},
			{
				id: 8,
				num: "35%",
				text: "interview pass rate",
			},
			{
				id: 9,
				num: "60%",
				text: "assessment center pass rate",
			},
			{
				id: 10,
				num: "29%",
				text: "all application success rate",
			},
		],
		heading_02: "Upcomming Events",
		events: [
			{
				id: 1,
				title: "Upcoming mangement interview",
				link: "/event-detail",
				company: "Envision Technologies Inc.",
				company_link: "https://www.google.com/",
				time: "8.30 Am- 10.30 AM",
				date: "20th",
			},
			{
				id: 2,
				title: "Upcoming mangement interview",
				link: "/event-detail",
				company: "Envision Technologies Inc.",
				company_link: "https://www.google.com/",
				time: "8.30 Am- 10.30 AM",
				date: "20th",
			},
		],
		heading_03: "Job application progress",
		btn: "Add new",
		btn_link: "/add-new-job",
		jobs: [
			{
				id: 1,
				src: "/images/companies/01.png",
				alt: "Surge",
				title: "Senior Full-Stack Software Engineer - React / Node.js",
				link: "/job-detail",
				company: "Envision Technologies Inc.",
				company_link: "https://www.google.com/",
				location: "United Kingdom",
				degree: "Degree in IT",
				salary: "100,000-120,000 / Yearly",
				act_btn: {
					edit: "/edit-job",
				},
			},
			{
				id: 2,
				src: "/images/companies/02.png",
				alt: "ABA",
				title: "Full-time Content Creator",
				para: "Soluta corrupti saepe porro rerum et nihil maiores adipisci quisquam architecto iste deserunt aut, quas ex explicabo corporis iure. Totam, itaque facere!",
				link: "/job-detail",
				company: "Envision Technologies Inc.",
				company_link: "https://www.google.com/",
				location: "United Kingdom",
				degree: "Degree in IT",
				salary: "100,000-120,000 / Yearly",
				act_btn: {
					edit: "/edit-job",
				},
			},
			{
				id: 3,
				src: "/images/companies/03.png",
				alt: "Asterik",
				title: "Senior Technical Specialist - Service Now",
				para: "Soluta corrupti saepe porro rerum et nihil maiores adipisci quisquam architecto iste deserunt aut, quas ex explicabo corporis iure. Totam, itaque facere!",
				link: "/job-detail",
				company: "Envision Technologies Inc.",
				company_link: "https://www.google.com/",
				location: "United Kingdom",
				degree: "Degree in IT",
				salary: "100,000-120,000 / Yearly",
				act_btn: {
					edit: "/edit-job",
				},
			},
			{
				id: 4,
				src: "/images/companies/04.png",
				alt: "Cuku",
				title: "Trainee Project Coordinator - Part time",
				para: "Soluta corrupti saepe porro rerum et nihil maiores adipisci quisquam architecto iste deserunt aut, quas ex explicabo corporis iure. Totam, itaque facere!",
				link: "/job-detail",
				company: "Envision Technologies Inc.",
				company_link: "https://www.google.com/",
				location: "United Kingdom",
				degree: "Degree in IT",
				salary: "100,000-120,000 / Yearly",
				act_btn: {
					edit: "/edit-job",
				},
			},
			{
				id: 5,
				src: "/images/companies/05.png",
				alt: "OKTA",
				title: "Software Engineer / Senior Software Engineer",
				para: "Soluta corrupti saepe porro rerum et nihil maiores adipisci quisquam architecto iste deserunt aut, quas ex explicabo corporis iure. Totam, itaque facere!",
				link: "/job-detail",
				company: "Envision Technologies Inc.",
				company_link: "https://www.google.com/",
				location: "United Kingdom",
				degree: "Degree in IT",
				salary: "100,000-120,000 / Yearly",
				act_btn: {
					edit: "/edit-job",
				},
			},
			{
				id: 6,
				src: "/images/companies/01.png",
				alt: "Surge",
				title: "Senior Full-Stack Software Engineer - React / Node.js",
				para: "Soluta corrupti saepe porro rerum et nihil maiores adipisci quisquam architecto iste deserunt aut, quas ex explicabo corporis iure. Totam, itaque facere!",
				link: "/job-detail",
				company: "Envision Technologies Inc.",
				company_link: "https://www.google.com/",
				location: "United Kingdom",
				degree: "Degree in IT",
				salary: "100,000-120,000 / Yearly",
				act_btn: {
					edit: "/edit-job",
				},
			},
			{
				id: 7,
				src: "/images/companies/02.png",
				alt: "ABA",
				title: "Full-time Content Creator",
				para: "Soluta corrupti saepe porro rerum et nihil maiores adipisci quisquam architecto iste deserunt aut, quas ex explicabo corporis iure. Totam, itaque facere!",
				link: "/job-detail",
				company: "Envision Technologies Inc.",
				company_link: "https://www.google.com/",
				location: "United Kingdom",
				degree: "Degree in IT",
				salary: "100,000-120,000 / Yearly",
				act_btn: {
					edit: "/edit-job",
				},
			},
			{
				id: 8,
				src: "/images/companies/03.png",
				alt: "Asterik",
				title: "Senior Technical Specialist - Service Now",
				para: "Soluta corrupti saepe porro rerum et nihil maiores adipisci quisquam architecto iste deserunt aut, quas ex explicabo corporis iure. Totam, itaque facere!",
				link: "/job-detail",
				company: "Envision Technologies Inc.",
				company_link: "https://www.google.com/",
				location: "United Kingdom",
				degree: "Degree in IT",
				salary: "100,000-120,000 / Yearly",
				act_btn: {
					edit: "/edit-job",
				},
			},
			{
				id: 9,
				src: "/images/companies/04.png",
				alt: "Cuku",
				title: "Trainee Project Coordinator - Part time",
				para: "Soluta corrupti saepe porro rerum et nihil maiores adipisci quisquam architecto iste deserunt aut, quas ex explicabo corporis iure. Totam, itaque facere!",
				link: "/job-detail",
				company: "Envision Technologies Inc.",
				company_link: "https://www.google.com/",
				location: "United Kingdom",
				degree: "Degree in IT",
				salary: "100,000-120,000 / Yearly",
				act_btn: {
					edit: "/edit-job",
				},
			},
			{
				id: 10,
				src: "/images/companies/05.png",
				alt: "OKTA",
				title: "Software Engineer / Senior Software Engineer",
				para: "Soluta corrupti saepe porro rerum et nihil maiores adipisci quisquam architecto iste deserunt aut, quas ex explicabo corporis iure. Totam, itaque facere!",
				link: "/job-detail",
				company: "Envision Technologies Inc.",
				company_link: "https://www.google.com/",
				location: "United Kingdom",
				degree: "Degree in IT",
				salary: "100,000-120,000 / Yearly",
				act_btn: {
					edit: "/edit-job",
				},
			},
		],
	},
	settings: {
		personal_info: {
			heading: "Profile information",
			dp: "/images/01.jpg",
			dp_alt: "DP",
		},
		opportunity_info: {
			heading: "Equal Opportunities",
		},
		education_info: {
			heading: "Education",
		},
		job_info: {
			heading: "Job preference",
		},
		change_pass: {
			heading: "Change Password",
		},
	},
	stats: {
		heading: "Application Overview",
		card: [
			{
				id: 1,
				num: "450",
				text: "Job Applied",
			},
			{
				id: 2,
				num: "1500",
				text: "online test complted",
			},
			{
				id: 3,
				num: "175",
				text: "Interview attened",
			},
			{
				id: 4,
				num: "938",
				text: "assement center attended",
			},
			{
				id: 5,
				num: "780",
				text: "Offer received",
			},
			{
				id: 6,
				num: "80%",
				text: "rate of passing intial stage",
			},
			{
				id: 7,
				num: "72%",
				text: "test pass rate",
			},
			{
				id: 8,
				num: "35%",
				text: "interview pass rate",
			},
			{
				id: 9,
				num: "60%",
				text: "assessment center pass rate",
			},
			{
				id: 10,
				num: "29%",
				text: "all application success rate",
			},
		],
		chart_title_01: "Nationality",
		chart_title_02: "Current Status",
		chart_title_03: "Degree Subject",
		chart_title_04: "Graduation Year",
		chart_title_05: "Opportunity Type",
		chart_title_06: "Industry Sector",
	},
	subscription: {
		heading_01: "Current Plan",
		top_bar: {
			title: "PLATINUM",
			para: "Your plan upgrade will be effective from 02 Feb 2022 and you will be charged $299/month.",
			btn_01: "Upgrade",
			btn_link_01: "?",
			btn_02: "Cancel",
			btn_link_02: "?",
		},
		heading_02: "Select Plan",
		block: [
			{
				id: 1,
				title: "SILVER",
				price: "$49",
				duration: "/per month, paid annually",
				para: "Et asperiores reiciendis magnam inventore ",
				list_true: [
					{
						id: 1,
						li: "Neque Nihil Adipisci",
					},
					{
						id: 2,
						li: "Qui Repellendus Quas",
					},
					{
						id: 3,
						li: "Aut Possimus Laboriosam",
					},
				],
				list_false: [
					{
						id: 4,
						li: "Et Tenetur In",
					},
					{
						id: 5,
						li: "Magnam Nihil Molestiae",
					},
					{
						id: 6,
						li: "Consequuntur Occaecati Eos",
					},
					{
						id: 7,
						li: "Modi Maiores Sequi",
					},
				],
				btn: "Get Started",
				btn_link: "/subscription",
			},
			{
				id: 2,
				title: "PLATINUM",
				price: "$149",
				duration: "/per month, paid annually",
				para: "Et asperiores reiciendis magnam inventore ",
				list_true: [
					{
						id: 1,
						li: "Neque Nihil Adipisci",
					},
					{
						id: 2,
						li: "Qui Repellendus Quas",
					},
					{
						id: 3,
						li: "Aut Possimus Laboriosam",
					},
				],
				list_false: [
					{
						id: 4,
						li: "Et Tenetur In",
					},
					{
						id: 5,
						li: "Magnam Nihil Molestiae",
					},
					{
						id: 6,
						li: "Consequuntur Occaecati Eos",
					},
					{
						id: 7,
						li: "Modi Maiores Sequi",
					},
				],
				btn: "Get Started",
				btn_link: "/subscription",
			},
			{
				id: 3,
				title: "GOLD",
				price: "$199",
				duration: "/per month, paid annually",
				para: "Et asperiores reiciendis magnam inventore ",
				list_true: [
					{
						id: 1,
						li: "Neque Nihil Adipisci",
					},
					{
						id: 2,
						li: "Qui Repellendus Quas",
					},
					{
						id: 3,
						li: "Aut Possimus Laboriosam",
					},
				],
				list_false: [
					{
						id: 4,
						li: "Et Tenetur In",
					},
					{
						id: 5,
						li: "Magnam Nihil Molestiae",
					},
					{
						id: 6,
						li: "Consequuntur Occaecati Eos",
					},
					{
						id: 7,
						li: "Modi Maiores Sequi",
					},
				],
				btn: "Get Started",
				btn_link: "/subscription",
			},
		],
	},
	error: {
		sec_bg: "/images/layer_02.svg",
		num: "404",
		title: "Page not found",
		para: "Let's pretend ..... !! You never saw that. Go back to the Homepage to find out more.",
		btn: "Back to the website",
		btn_link: "/",
	},
};

export default Data;
