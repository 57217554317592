import React from "react";
import ReactDOMClient from "react-dom/client";
import "./assets/css/bootstrap.min.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "react-datepicker/dist/react-datepicker.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Provider } from "react-redux";
import store from "./states/store";

import "./App.scss";
import "./custom.css";
import "./style.css";
import App from "./App.jsx";

import "react-toastify/dist/ReactToastify.css";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

// const stripePromise = loadStripe(
//   "pk_test_51I9TpTE5cCQwA2COvs1kw7HeYFadf7pNGk7X2voJYrEAS5SVHNdwsJRFgjMpq5UzJsutiY4NbO2wuhybL8iGsThS009Ypdbr3a"
// );

const stripePromise = loadStripe(
  "pk_live_51K06xhKHowCkGSptw4dftERD5DVJWxdaNj57Rd3vjDQVoCvGJKPhxvUs8Muov96Fz0gMcgthYVvp91FTjFF4qd5J008OFmpweO"
);

ReactDOMClient.createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <Elements stripe={stripePromise}>
      <App />
    </Elements>
  </Provider>
);
