import parse from "html-react-parser";
import moment from "moment";
import * as paths from "../constants/paths";

export function doObjToFormData(obj) {
  var formData = new FormData();
  for (var key in obj) {
    if (Array.isArray(obj[key])) {
      for (let [keyv, value] of Object.entries(obj[key])) {
        formData.append(key + "[]", JSON.stringify(value));
      }
    } else {
      if (typeof obj[key] == "object") {
        formData.append(key, JSON.stringify(obj[key]));
      } else {
        formData.append(key, obj[key]);
      }
    }
  }
  return formData;
}

export function doObjToFormDataWithoutString(obj) {
  var formData = new FormData();
  for (var key in obj) {
    if (Array.isArray(obj[key])) {
      for (let [keyv, value] of Object.entries(obj[key])) {
        formData.append(key + "[]", value);
      }
    } else {
      if (typeof obj[key] == "object") {
        formData.append(key, obj[key]);
      } else {
        formData.append(key, obj[key]);
      }
    }
  }
  return formData;
}

export function doFirstUpperRestLower(word) {
  const lower = word.toLowerCase();
  return word.charAt(0).toUpperCase() + lower.slice(1);
}

export function doParseHTML(string) {
  return parse(string);
}

export function getBackgroundImageUrl(src) {
  const base_api_url = paths.API_CMS_BG_IMAGES_URL;
  return base_api_url + src;
}

export function getBackgroundImageUrlThumb(src, thumb = 1) {
  const base_api_url = paths.API_CMS_BG_IMAGES_URL;
  if (thumb > 1) return base_api_url + thumb + "p_" + src;
  else return base_api_url + "thumb_" + src;
}

export function getUploadsUrl(folder, src) {
  const base_api_url = paths.API_UPLOADS_URL;
  return base_api_url + folder + "/" + src;
}

export function eventDateFormat(date) {
  return moment(date).format("DD, MMMM YYYY");
}

export function eventTimeFormat(time) {
  return moment(time, "HHmm").format("hh:mm A");
}

export function eventTimeFormatNew(time) {
  return moment(time, "HHmm").format("hh A");
}

export function onlyDayThreeletters(date) {
  return moment(date).format("ddd");
}

export function onlyDateTwoletters(date) {
  return moment(date).format("DD");
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function nowPlus6Days() {
  let days = [];
  let daysRequired = 7;

  for (let i = 0; i <= daysRequired; i++) {
    days.push(moment().add(i, "days").format("YYYY-MM-DD"));
  }
  return days;
}

export function jobProgressColor(value) {
  switch (value) {
    case "application_in_progress":
    case "online_test_completed":
    case "first_interview_completed":
    case "second_interview_completed":
    case "assessment_day_completed":
      return "_amber";
    case "offer_received":
      return "_green";
    case "online_test_failed":
    case "first_interview_failed":
    case "second_interview_failed":
    case "assessment_day_failed":
      return "_red";
    default:
      return "";
  }
}

export function getActiveClassname(value) {
  switch (value) {
    case 0:
      return "first_active";
    case 1:
      return "second_active";
    case 2:
      return "third_active";
    case 3:
      return "four_active";
    case 4:
      return "five_active";
    default:
      return "";
  }
}

export function makeSalaryString(min, max, interval) {
  min = Number(min);
  max = Number(max);
  if (min === 0 && max === 0) return "Competitive";
  else if (min > 0 && max === 0) return `${min} / ${interval}`;
  else if (min > 0 && max > 0) return `${min} - ${max} / ${interval}`;
  else return "Not Specified";
}

export function priceFormat(num) {
  if (num === null || num === "") return 0;

  const dec = num.split(".")[1];
  const len = dec && dec.length > 2 ? dec.length : 2;
  return Number(num).toFixed(len);
}

export function checkReferralLink(ref) {
  if (
    ref === undefined ||
    ref === "" ||
    ref === null ||
    ref == null ||
    ref === "null" ||
    ref === "null" ||
    ref === "undefined"
  ) {
    return false;
  } else {
    return true;
  }
}

export function websiteLink(link) {
  const referralLink = localStorage.getItem("ref");
  const compaign_name = localStorage.getItem("compaign_name");

  if (checkReferralLink(referralLink))
    return `${link}?ref=${referralLink}&compaign_name=${compaign_name}`;
  else return link;
}
