import React from "react";
import { Link } from "react-router-dom";
import SocialLinks from "./SocialLinks";
import { useSelector } from "react-redux";
import Logo from "./Logo";
import Text from "../common/Text";
import { websiteLink } from "../../helpers/helpers";

function Footer() {
  const siteSettings = useSelector(
    (state) => state.fetchSiteSettings.siteSettings
  );

  const data = {
    list_01: [
      {
        id: 1,
        icon: "/images/icon-location.svg",
        alt: "Location",
        text: "Kemp House, 152 - 160 City Road, London, EC1V 2NX"
        // link: "",
      },
      {
        id: 2,
        icon: "/images/icon-sms-tracking.svg",
        alt: "Envelope",
        text: "support@ukvisajobs.com",
        link: "mailto:support@ukvisajobs.com"
      },
      {
        id: 3,
        icon: "/images/icon-call-incoming.svg",
        alt: "Phone",
        text: "0112-345-345-5544",
        link: "tel:0112-345-345-5544"
      }
    ],
    list_02: [
      {
        id: 1,
        text: "Jobs",
        link: "/open-jobs/1"
      },
      {
        id: 2,
        text: "Events",
        link: "/events"
      },
      {
        id: 3,
        text: "About Us",
        link: "/about"
      },
      {
        id: 3,
        text: "FAQs",
        link: "/faq"
      }
      // {
      //   id: 4,
      //   text: "Subscriptions",
      //   link: "/Subscription"
      // }
    ],
    list_03: [
      {
        id: 1,
        text: "Australia",
        link: "/"
      },
      {
        id: 2,
        text: "European Union",
        link: "/"
      },
      {
        id: 3,
        text: "Asia Pacific",
        link: "/"
      },
      {
        id: 4,
        text: "Middle East",
        link: "/"
      },
      {
        id: 4,
        text: "Global",
        link: "/"
      }
    ],
    sm_list: [
      {
        id: 1,
        text: "Terms & conditions",
        link: "/terms-conditions"
      },
      {
        id: 2,
        text: "Privacy Policy",
        link: "/privacy-policy"
      }
    ],
    para: "This design is intellectual property of Ilghar Consulting Limited. It should not be copied or reproduced to be used for any purposes other than supporting the web development for Ilghar Consulting Limited (Careeful). Ilghar Consulting Limited will constantly engage with external parties to prevent copy right violations.",
    copyright: {
      start: "Copyright © 2022 ",
      mid: "Emissary Recruiting Solutions, Inc.",
      last: "All rights reserved."
    }
  };

  return (
    <>
      {siteSettings && (
        <footer>
          <div className="contain">
            <div className="flex_row main_row row">
              <div className="col-xl-4">
                <div className="in_col">
                  <Logo logo={siteSettings.site_footer_logo} />
                  {/* <h4>
                    <Text string={siteSettings.site_first_section_heading} />
                  </h4> */}
                  <ul className="list info_list">
                    <li>
                      <img
                        src="/images/icon-location.svg"
                        alt="Failed to load icon"
                      />
                      <span>
                        <Text string={siteSettings.site_first_section_sub_1} />
                      </span>
                    </li>
                    <li>
                      <img
                        src="/images/icon-sms-tracking.svg"
                        alt="Failed to load icon"
                      />
                      <a
                        href={`mailto:${siteSettings.site_first_section_sub_2}`}
                      >
                        <Text string={siteSettings.site_first_section_sub_2} />
                      </a>
                    </li>
                    {/* <li>
                      <img
                        src="/images/icon-call-incoming.svg"
                        alt="Failed to load icon"
                      />
                      <a href={`tel:${siteSettings.site_first_section_sub_3}`}>
                        <Text string={siteSettings.site_first_section_sub_3} />
                      </a>
                    </li> */}
                  </ul>
                  <SocialLinks siteSettings={siteSettings} />
                </div>
              </div>
              <div className="col-lg mid_col">
                <div className="in_col">
                  <h4>
                    <Text string={siteSettings.site_second_section_heading} />
                  </h4>
                  <ul className="list">
                    {data.list_02.map((val) => {
                      return (
                        <li key={val.id}>
                          <Link to={websiteLink(val.link)}>{val.text}</Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              {/* <div className="col-lg">
                <div className="in_col">
                  <h4>Other Regions
                  </h4>
                  <ul className="list">
                    {data.list_03.map((val) => {
                      return (
                        <li key={val.id}>
                          <Link to={val.link}>{val.text}</Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div> */}
              <div className="col-lg">
                <div className="in_col">
                  <h4>
                    <Text string={siteSettings.site_third_section_heading} />
                  </h4>
                  <ul className="list">
                    {data.sm_list.map((val) => {
                      return (
                        <li key={val.id}>
                          <Link to={websiteLink(val.link)}>{val.text}</Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              {/* <div className="col-xl-4">
                <div className="in_col">
                  <Newsletter siteSettings={siteSettings} />
                </div>
              </div> */}
            </div>
            <p className="small mt-4 pt-5 mb-0">
              <Text string={siteSettings.site_footer_text} />
            </p>
            <hr />
            {/* <ul className="sm_list">
              {data.sm_list.map((val) => {
                return (
                  <li key={val.id}>
                    <Link to={val.link}>{val.text}</Link>
                  </li>
                );
              })}
            </ul> */}
            <div className="copyright">
              {/* <ul className="sm_list">
                {data.sm_list.map((val) => {
                  return (
                    <li key={val.id}>
                      <Link to={val.link}>{val.text}</Link>
                    </li>
                  );
                })}
              </ul> */}
              <p className="text-center">
                <Text string={siteSettings.site_copyright} />
              </p>
              {/* <SocialLinks siteSettings={siteSettings} /> */}
            </div>
          </div>
        </footer>
      )}
    </>
  );
}

export default Footer;
