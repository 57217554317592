import http from "../../helpers/http";
import httpBlob from "../../helpers/http-blob";
import * as helpers from "../../helpers/helpers";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import Text from "../../components/common/Text";

import {
  FETCH_AMBASSADOR_PROPGRAMME,
  FETCH_AMBASSADOR_PROPGRAMME_SUCCESS,
  FETCH_AMBASSADOR_PROPGRAMME_FAILED,
  SAVE_REFERRAL_LINK,
  SAVE_REFERRAL_LINK_SUCCESS,
  SAVE_REFERRAL_LINK_FAILED,
  REQUEST_WITHDRAW_EARNINGS,
  REQUEST_WITHDRAW_EARNINGS_SUCCESS,
  REQUEST_WITHDRAW_EARNINGS_FAILED
} from "./actionTypes";

export const fetchAmbassadorProgramme = () => (dispatch) => {
  dispatch({
    type: FETCH_AMBASSADOR_PROPGRAMME,
    payload: null
  });
  http
    .post(
      "user/ambassador-programme",
      helpers.doObjToFormData({ token: localStorage.getItem("authToken") })
    )
    .then(({ data }) => {
      dispatch({
        type: FETCH_AMBASSADOR_PROPGRAMME_SUCCESS,
        payload: data
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_AMBASSADOR_PROPGRAMME_FAILED,
        payload: error
      });
      const { data } = error.response;
      localStorage.removeItem("authToken");
      window.location.reload();
    });
};

export const saveReferralLink = (formData) => (dispatch) => {
  formData = { ...formData, token: localStorage.getItem("authToken") };
  formData = helpers.doObjToFormData(formData);

  dispatch({
    type: SAVE_REFERRAL_LINK,
    payload: null
  });
  httpBlob
    .post("user/save-referral-link", formData)
    .then(({ data }) => {
      if (data.status) {
        toast.success("Referral link saved successfully.", TOAST_SETTINGS);
        dispatch({
          type: SAVE_REFERRAL_LINK_SUCCESS,
          payload: data
        });
      } else {
        if (data.validationErrors) {
          toast.error(
            <Text string={data.validationErrors} parse={true} />,
            TOAST_SETTINGS
          );
          dispatch({
            type: SAVE_REFERRAL_LINK_FAILED,
            payload: null
          });
        }
      }
    })
    .catch((error) => {
      dispatch({
        type: SAVE_REFERRAL_LINK_FAILED,
        payload: error
      });
      const { data } = error.response;
      localStorage.removeItem("authToken");
      window.location.reload();
    });
};

export const requestWithdrawEarnings = (formData) => (dispatch) => {
  formData = { ...formData, token: localStorage.getItem("authToken") };
  formData = helpers.doObjToFormData(formData);

  dispatch({
    type: REQUEST_WITHDRAW_EARNINGS,
    payload: null
  });
  httpBlob
    .post("user/request-withdraw-earnings", formData)
    .then(({ data }) => {
      if (data.status) {
        toast.success("Request has been sent to admin.", TOAST_SETTINGS);
        dispatch({
          type: REQUEST_WITHDRAW_EARNINGS_SUCCESS,
          payload: data
        });
        setTimeout(() => {
          window.location.reload();
        }, 4000);
      } else {
        if (data.validationErrors) {
          toast.error(
            <Text string={data.validationErrors} parse={true} />,
            TOAST_SETTINGS
          );
          dispatch({
            type: REQUEST_WITHDRAW_EARNINGS_FAILED,
            payload: null
          });
        }
      }
    })
    .catch((error) => {
      dispatch({
        type: REQUEST_WITHDRAW_EARNINGS_FAILED,
        payload: error
      });
      const { data } = error.response;
      localStorage.removeItem("authToken");
      window.location.reload();
    });
};
