import React, { useState } from "react";
import JobBlk from "../../common/JobBlk";
import JobApplyPopup from "../../common/JobApplyPopup";

import { applyJob, saveJobAction } from "../../../states/actions/fetchJobs";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const JobListing = ({
  jobs,
  isSearching,
  saveJob,
  isJobSaving,
  isJobReporting,
  handleReportAnJob,
  handleShowSigninPopup,
  showSigninPopup,
  showReportPopup,
  handleToggleReportPopup
}) => {
  const dispatch = useDispatch();
  const isJobApplying = useSelector((state) => state.fetchJobs.isJobApplying);
  const [showApplyPopup, setShowApplyPopup] = useState(false);
  const [popupJob, setPopupJob] = useState(null);
  const navigate = useNavigate();

  const dismissPopup = () => {
    setShowApplyPopup(false);
    setPopupJob(null);
  };

  const handleJobApply = (id) => {
    dispatch(applyJob({ id }));
  };

  // const handleJobApplyPopup = (job) => {
  //   setShowApplyPopup(true);
  //   setPopupJob(job);
  //   ////
  // };

  const handleTitleClick = (job) => {
    if (job.is_internal_or_external === "external") {
      if (isJobSaving) {
        return false;
      }
      setShowApplyPopup(true);
      setPopupJob(job);
      window.open(job.job_link, "_blank");
    } else {
      navigate(`/job-profile/${job.id}`);
    }
  };

  return (
    <>
      <div className="flex_row job_row row">
        {isSearching ? (
          <div className="col">Fetching...</div>
        ) : jobs && jobs.length === 0 ? (
          <div className="col">No record found.</div>
        ) : (
          jobs &&
          jobs.map((job) => {
            return (
              <div className="col" key={job.id}>
                <JobBlk
                  job={job}
                  saveJob={saveJob}
                  isJobSaving={isJobSaving}
                  handleJobApplyPopup={handleTitleClick}
                  handleShowSigninPopup={handleShowSigninPopup}
                  showSigninPopup={showSigninPopup}
                />
              </div>
            );
          })
        )}
      </div>
      <br />
      {showApplyPopup && (
        <JobApplyPopup
          job={popupJob}
          dismissPopup={dismissPopup}
          handleJobApply={handleJobApply}
          isJobApplying={isJobApplying}
          saveJob={saveJob}
          isJobSaving={isJobSaving}
          isJobReporting={isJobReporting}
          handleReportAnJob={handleReportAnJob}
          handleShowSigninPopup={handleShowSigninPopup}
          showSigninPopup={showSigninPopup}
          showReportPopup={showReportPopup}
          handleToggleReportPopup={handleToggleReportPopup}
        />
      )}
    </>
  );
};

export default JobListing;
