import http from "../../helpers/http";
import { doObjToFormData } from "../../helpers/helpers";

import {
  FETCH_SUBSCRIPTION_CONTENT,
  FETCH_SUBSCRIPTION_CONTENT_SUCCESS,
  FETCH_SUBSCRIPTION_CONTENT_FAILED
} from "./actionTypes";

export const fetchSubscription = (formData) => (dispatch) => {
  dispatch({
    type: FETCH_SUBSCRIPTION_CONTENT,
    payload: null
  });
  http
    .post(
      "subscription",
      doObjToFormData({
        token: localStorage.getItem("authToken"),
        ref: formData.ref
      })
    )
    .then(({ data }) => {
      dispatch({
        type: FETCH_SUBSCRIPTION_CONTENT_SUCCESS,
        payload: data
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_SUBSCRIPTION_CONTENT_FAILED,
        payload: error
      });
      const { data } = error.response;
      localStorage.removeItem("authToken");
      window.location.reload();
    });
};
