import React, { useState } from "react";
import { Link } from "react-router-dom";
import Text from "../../common/Text";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { cancelSubscriptionPlan } from "../../../states/actions/fetchDashSubscription";
import FormProcessingSpinner from "../../common/FormProcessingSpinner";
import { eventDateFormat } from "../../../helpers/helpers";

const SubscriptionManage = ({ content, subscribeArr, plan }) => {
  const dispatch = useDispatch();
  const CANCEL_PLAN_REASONS = [
    "I have found a job",
    "I could not find what I was looking for",
    "I subscribed only temporarily",
    "I am not looking for a job in the UK anymore",
    "I could find the same content elsewhere",
    "There is no need to pay for this content",
  ];

  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const firstSubmit = (data) => {
    dispatch(cancelSubscriptionPlan(data));
  };

  const [popupCancle, setPopupCancle] = useState(false);
  const PopupCancleActive = () => {
    setPopupCancle(!popupCancle);
  };

  const isCancelFormProcessing = useSelector(
    (state) => state.fetchDashSubscription.isCancelFormProcessing
  );

  return (
    <>
      <div className="top_head mb-3">
        <h4 className="heading_dash">
          <Text string={content.banner_heading} />
        </h4>
      </div>
      <div className="top_bar mini_text_blk">
        {subscribeArr ? (
          <>
            <div className="txt">
              <h3 className="color">{plan.plan_name} Subscription</h3>
              <p>{`Your plan is activated from ${eventDateFormat(
                subscribeArr.start_date
              )} and you will be charged £${subscribeArr.price}/${
                plan.plan_interval
              }.`}</p>
              <p>{`And renewal date is: ${eventDateFormat(
                subscribeArr.end_date
              )}.`}</p>
            </div>
            <div className="btn_blk flex-md-nowrap ms-md-5">
              <Link
                to="/subscription-offers"
                className="site_btn long"
                style={{ display: "none" }}
              >
                Upgrade
              </Link>
              <div
                className="site_btn long light"
                onClick={() => PopupCancleActive()}
              >
                Cancel
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="txt">
              <p>Your subscribed plan will appear here.</p>
            </div>
          </>
        )}
      </div>

      <div
        className={
          popupCancle && subscribeArr
            ? "popup text_popup active"
            : "popup text_popup"
        }
      >
        <div className="table_dv">
          <div className="table_cell">
            <div className="contain">
              <div className="_inner">
                <button
                  type="button"
                  className="x_btn"
                  onClick={() => PopupCancleActive()}
                ></button>
                <div className="heading_text">
                  <h4>Please choose the reason for cancellation.</h4>
                </div>
                <form
                  action=""
                  method="POST"
                  onSubmit={handleSubmit(firstSubmit)}
                >
                  <div className="form_row row">
                    {CANCEL_PLAN_REASONS &&
                      CANCEL_PLAN_REASONS.map((value, index) => (
                        <div className="col-sm-12">
                          <div className="lbl_btn">
                            <input
                              type="radio"
                              {...register("reason", {
                                required: "Please select a reason.",
                              })}
                              value={value}
                            />
                            <label htmlFor="">{value}</label>
                          </div>
                        </div>
                      ))}
                  </div>
                  <span className="validation-error">
                    {errors.reason?.message}
                  </span>
                  <div className="br"></div>
                  <div className="btn_blk text-center">
                    <button
                      className="site_btn"
                      type="submit"
                      disabled={isCancelFormProcessing}
                    >
                      Submit
                      <FormProcessingSpinner
                        isFormProcessing={isCancelFormProcessing}
                      />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscriptionManage;
