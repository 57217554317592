import React, { useState } from "react";
import { Link } from "react-router-dom";
import ImageControl from "../../common/ImageControl";
import Text from "../../common/Text";
import RegisterBlk from "./RegisterBlk";
import * as helpers from "../../../helpers/helpers";
import SigninPopup from "../../common/SiginPopup";

const EventDetailBlk = ({ event, reg_blk, id }) => {
  const [showSigninPopup, setShowSigninPopup] = useState(false);
  return (
    <>
      <div className="event_detail">
        <h2>
          <Text string={event.title} />
        </h2>
        <div className="btm">
          <div className="time">
            {helpers.eventDateFormat(event.event_date)} @
            {helpers.eventTimeFormatNew(event.time_from)}
            {", "}
            {event.time_zone}
          </div>
          <div className="type small">
            <img
              src={process.env.PUBLIC_URL + "/images/icon-video2.svg"}
              alt=""
            />
            {helpers.doFirstUpperRestLower(event.cat_name)} Event
          </div>
          {/* <div className="price color">{price}</div> */}
        </div>
        <div className="fig">
          <ImageControl
            src={event.image}
            folder="events"
            specificWidth="1000p_"
          />
        </div>
        <div className="txt">
          <Text string={event.description} parse={true} />
        </div>
        <div className="btn_blk mt-5 mb-5"></div>
        <RegisterBlk {...reg_blk} id={id} />
      </div>
    </>
  );
};

export default EventDetailBlk;
