import React, { useEffect, useState } from "react";
import Data from "../../dummy";
import JobList from "./JobList";

import {
  fetchJobs,
  searchJobsData,
  saveJobAction,
  applyJob,
  reportAnJob,
  reportAnJobPopup
} from "../../../states/actions/fetchJobs";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";
import { ToastContainer } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

const OpenJobs = () => {
  const [showSigninPopup, setShowSigninPopup] = useState(false);
  const navigate = useNavigate();
  const { page } = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchJobs.content);
  const isLoading = useSelector((state) => state.fetchJobs.isLoading);
  const isSearching = useSelector((state) => state.fetchJobs.isSearching);
  const isJobSaving = useSelector((state) => state.fetchJobs.isJobSaving);
  const isJobApplying = useSelector((state) => state.fetchJobs.isJobApplying);
  const isJobReporting = useSelector((state) => state.fetchJobs.isJobReporting);

  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  const showReportPopup = useSelector(
    (state) => state.fetchJobs.showReportPopup
  );

  const jobs = useSelector((state) => state.fetchJobs.jobs);

  const totalJobs = useSelector((state) => state.fetchJobs.totalJobs);

  const plan = useSelector((state) => state.fetchSiteSettings.plan);

  const {
    content,
    cities,
    cats,
    types,
    degree_req,
    industries,
    meta_desc,
    site_settings
  } = data;
  useEffect(() => {
    dispatch(fetchJobs());
  }, []);

  const searchJobs = (filters) => {
    dispatch(searchJobsData(filters));
  };

  const saveJob = (formData) => {
    dispatch(saveJobAction(formData));
  };

  const handleReportAnJob = (formData) => {
    dispatch(reportAnJob(formData));
  };

  const handleShowSigninPopup = () => {
    setShowSigninPopup(!showSigninPopup);
  };

  const handleToggleReportPopup = () => {
    dispatch(reportAnJobPopup());
  };

  useDocumentTitle(data.page_title);
  return (
    <>
      {isLoading || isSiteSettingsLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <ToastContainer />
          <JobList
            data={Data.open_jobs}
            jobs={jobs}
            cities={cities}
            cats={cats}
            types={types}
            degree_req={degree_req}
            industries={industries}
            searchJobs={searchJobs}
            isSearching={isSearching}
            saveJob={saveJob}
            isJobSaving={isJobSaving}
            isJobReporting={isJobReporting}
            handleReportAnJob={handleReportAnJob}
            handleShowSigninPopup={handleShowSigninPopup}
            showSigninPopup={showSigninPopup}
            showReportPopup={showReportPopup}
            handleToggleReportPopup={handleToggleReportPopup}
            totalJobs={totalJobs}
          />
        </>
      )}
    </>
  );
};

export default OpenJobs;
