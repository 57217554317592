import React from "react";
import Text from "../../common/Text";
import { Link } from "react-router-dom";
import * as helpers from "../../../helpers/helpers";
import { websiteLink } from "../../../helpers/helpers";

function Events({ content, events }) {
  return (
    <>
      <section id="Featured_events">
        <div className="contain">
          <div className="sec_heading text-center">
            <h5>
              <Text string={content.sec5_heading} />
            </h5>
            <h2>
              <Text string={content.sec5_tagline} />
            </h2>
          </div>
          <div className="flex">
            {events &&
              events.map((event) => (
                <div className="col">
                  <div className="inner">
                    <div className="event_header">
                      <div className="date_event">
                        <span>
                          {helpers.onlyDayThreeletters(event.event_date)}
                        </span>
                        <strong>
                          {helpers.onlyDateTwoletters(event.event_date)}
                        </strong>
                      </div>
                      <div className="cntnt">
                        {/* <p>{event.cat_name} Events</p> */}
                        <h3>
                          <Link to={websiteLink(`/event-detail/${event.id}`)}>
                            <Text string={event.title} />
                          </Link>
                        </h3>
                      </div>
                    </div>
                    <div className="event_bdy">
                      <ul className="specific_info">
                        <li>
                          <img src="images/new/video.svg" alt="" />{" "}
                          <span>{event.cat_name}</span>
                        </li>
                        <li>
                          <img src="images/new/calendar.svg" alt="" />{" "}
                          <span>
                            {helpers.eventDateFormat(event.event_date)} @
                            {helpers.eventTimeFormatNew(event.time_from)}
                            {", "}
                            {event.time_zone}
                          </span>
                        </li>
                      </ul>
                      <p>
                        <Text
                          string={event.short_description}
                          parse={false}
                          length={300}
                        />
                      </p>
                      <Link
                        className="site_btn md"
                        to={websiteLink(`/event-detail/${event.id}`)}
                      >
                        Register Now
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div className="btn_blk text-center">
            <Link to={websiteLink(`/events`)} className="site_btn blank">
              <Text string={content.sec5_button_text} />
            </Link>
          </div>
        </div>
      </section>
    </>
  );
}

export default Events;
