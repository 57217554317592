import React, { useState } from "react";
import FormProcessingSpinner from "../common/FormProcessingSpinner";
import { useForm } from "react-hook-form";
import { priceFormat } from "../../helpers/helpers";
import { useDispatch, useSelector } from "react-redux";
import { requestWithdrawEarnings } from "../../states/actions/fetchAmbassadorProgramme";

function WithdrawEarningsPopup({ job, dismiss, data }) {
  const dispatch = useDispatch();
  const { total_remaining_payouts } = data;

  const isFormProcessing = useSelector(
    (state) => state.fetchAmbassadorProgramme.isFormProcessing
  );
  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm();

  const firstSubmit = (formData) => {
    dispatch(requestWithdrawEarnings(formData));
  };

  return (
    <>
      <div className="popup text_popup active">
        <div className="table_dv">
          <div className="table_cell">
            <div className="contain">
              <div className="_inner">
                <button
                  type="button"
                  className="x_btn"
                  onClick={dismiss}
                ></button>
                <div className="heading_text">
                  <h4>
                    Available Balance: £{priceFormat(total_remaining_payouts)}
                  </h4>
                </div>
                <form
                  action=""
                  method="POST"
                  onSubmit={handleSubmit(firstSubmit)}
                >
                  <div className="form_row row">
                    <div className="col-sm-12">
                      <h5>Paypal Email</h5>
                      <input
                        type="text"
                        className="input"
                        {...register("paypal_email", {
                          required: "Paypal Email is required.",
                          pattern: {
                            value:
                              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Please enter a valid email"
                          }
                        })}
                      />
                      <span className="validation-error">
                        {errors.paypal_email?.message}
                      </span>
                    </div>
                  </div>
                  <div className="br"></div>
                  <div className="btn_blk text-center">
                    <button
                      className="site_btn"
                      type="submit"
                      disabled={isFormProcessing}
                    >
                      Request Payout
                      <FormProcessingSpinner
                        isFormProcessing={isFormProcessing}
                      />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WithdrawEarningsPopup;
