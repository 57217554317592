import React, { useEffect, useRef, useState } from "react";
import ChangePass from "./ChangePass";
import { useForm } from "react-hook-form";
import ImageControl from "../../common/ImageControl";

const Settings = ({
  data,
  mem,
  isFormProcessing,
  saveProfileSettings,
  changePassword,
  isPassChangeProcessing
}) => {
  const [previewImage, setPreviewImage] = useState(null);
  const [image, setImage] = useState(null);
  const profilePicRef = useRef(null);
  // const [formVal, setFormVal] = useState({
  //   fname: mem.mem_fname,
  //   lname: mem.mem_lname,
  //   phone: mem.mem_phone,
  //   email: mem.mem_email,
  //   language: mem.mem_language,
  //   nationality: mem.mem_nationality,
  //   edu_current: mem.mem_current_status,
  //   edu_uni: mem.mem_university,
  //   edu_degree: mem.mem_subject,
  //   edu_graduation: mem.mem_graduate_year,
  //   job_type: mem.mem_opportunity,
  //   sector: mem.mem_industry,
  //   disability: mem.mem_disablity,
  //   profile: null
  // });

  const {
    register,
    watch,
    formState: { errors },
    handleSubmit
  } = useForm();

  const {
    personal_info,
    opportunity_info,
    education_info,
    job_info,
    change_pass
  } = data;

  // const handleInputChange = (e) => {
  //   e.preventDefault();
  //   setFormVal({ ...formVal, [e.target.name]: e.target.value });
  // };

  const onSubmit = (data) => {
    if (image !== null) data.profile = image.target.files;
    saveProfileSettings(data);
  };

  const handlePhotoSelect = (e) => {
    e.preventDefault();
    profilePicRef.current.click();
  };

  const handleProfilePicChange = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setImage(e);
      setPreviewImage(URL.createObjectURL(e.target.files[0]));
    }
  };
  return (
    <>
      <section id="settings">
        <div className="contain-fluid">
          <form action="" method="POST" onSubmit={handleSubmit(onSubmit)}>
            <h4 className="heading">{personal_info.heading}</h4>
            <div className="dp_blk upLoadDp">
              <div className="ico fill round">
                {previewImage != null ? (
                  <img src={previewImage} alt={`Error Loading Preview Image`} />
                ) : mem.mem_image == null || mem.mem_image == "" ? (
                  <img
                    src="/images/new/frame.svg"
                    alt={`Error Loading Preview Image`}
                  />
                ) : (
                  <ImageControl
                    src={mem.mem_image}
                    folder="members"
                    alt={`Error Loading Preview Image`}
                    isThumb={true}
                  />
                )}
              </div>
              <div className="txt">
                <div className="upload_btn_blk">
                  {/*<button
                    type="button"
                    className="site_btn sm small"
                    onClick={handlePhotoSelect}
                  >
                    Upload Profile
                  </button>
                */}
                  <div className="mini_image">
                    <img src="images/new/upload.svg" alt="" />
                  </div>
                  <span className="color" onClick={handlePhotoSelect}>
                    Upload new
                  </span>
                  <input
                    className="sm small"
                    type="file"
                    id="profile"
                    {...register("profile")}
                    onChange={handleProfilePicChange}
                    ref={profilePicRef}
                    hidden
                  />
                </div>
                {/* <div className="small mt-3">
                  Acceptable only jpg, png <br /> The maximum file size is 500
                  kb and the minimum size is 80 kb.
                </div> */}
              </div>
            </div>
            <div className="br"></div>
            <h4 className="heading">Basic Information</h4>
            <div className="form_row row">
              <div className="col-sm-6 col-xs-6">
                <h5>First Name</h5>
                <div className="form_blk">
                  <input
                    defaultValue={mem.mem_fname}
                    type="text"
                    id="fname"
                    className="input"
                    {...register("fname", {
                      required: "First Name is required.",
                      maxLength: {
                        value: 20,
                        message:
                          "First Name should Not be greater than 20 characters."
                      },
                      minLength: {
                        value: 2,
                        message:
                          "First Name should be greater than atleast 2 characters."
                      }
                    })}
                  />
                  <span className="validation-error">
                    {errors.fname?.message}
                  </span>
                </div>
              </div>
              <div className="col-sm-6 col-xs-6">
                <h5>Last Name</h5>
                <div className="form_blk">
                  <input
                    defaultValue={mem.mem_lname}
                    type="text"
                    id="lanme"
                    className="input"
                    {...register("lname", {
                      required: "Last Name is required.",
                      maxLength: {
                        value: 20,
                        message:
                          "Last Name should Not be greater than 20 characters."
                      },
                      minLength: {
                        value: 2,
                        message:
                          "Last Name should be greater than atleast 2 characters."
                      }
                    })}
                  />
                  <span className="validation-error">
                    {errors.lname?.message}
                  </span>
                </div>
              </div>
              <div className="col-sm-6 col-xs-6">
                <h5>Phone Number</h5>
                <div className="form_blk">
                  <input
                    defaultValue={mem.mem_phone}
                    type="text"
                    id="phone"
                    className="input"
                    {...register("phone", {
                      required: "Phone number is required."
                    })}
                  />
                  <span className="validation-error">
                    {errors.phone?.message}
                  </span>
                </div>
              </div>
              <div className="col-sm-6 col-xs-6">
                <h5>Email Address</h5>
                <div className="form_blk">
                  <input
                    defaultValue={mem.mem_email}
                    type="text"
                    id="email"
                    className="input"
                    readOnly
                  />
                </div>
              </div>
            </div>

            <div className="btn_blk form_btn">
              <button
                type="submit"
                className="site_btn lg long"
                disabled={isFormProcessing}
              >
                Update Information
              </button>
            </div>
          </form>

          <div className="br"></div>
          <ChangePass
            val={change_pass}
            changePassword={changePassword}
            isPassChangeProcessing={isPassChangeProcessing}
          />
        </div>
      </section>
    </>
  );
};
export default Settings;
