import React, { useEffect } from "react";
import Data from "../../dummy";
import Cover from "../../common/Cover";
import PrivacyPolicy from "./PrivacyPolicy";

import { fetchPrivacy } from "../../../states/actions/fetchPrivacy";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";
import { useSearchParams } from "react-router-dom";
import useReferralLink from "../../../hooks/useReferralLink";

const Privacy = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const referralLink = useReferralLink(
    searchParams.get("ref"),
    searchParams.get("compaign_name")
  );
  const { cover } = Data.privacy;
  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchPrivacy.content);
  const isLoading = useSelector((state) => state.fetchPrivacy.isLoading);
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  const { content } = data;

  useEffect(() => {
    dispatch(fetchPrivacy({ ref: referralLink }));
  }, []);

  useDocumentTitle(data.page_title);
  return (
    <>
      {isLoading || isSiteSettingsLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <Cover data={cover} content={content} />
          <PrivacyPolicy content={content} />
        </>
      )}
    </>
  );
};

export default Privacy;
