import React from "react";
import { Link } from "react-router-dom";
import * as helpers from "../../helpers/helpers";
import Text from "./Text";

const EventMiniBlk = ({ event }) => {
  return (
    <>
      <div className="event_mini_blk dash_event_blk">
        <div className="date date_event">
          <span>{helpers.onlyDayThreeletters(event.event_date)}</span>
          <strong>{helpers.onlyDateTwoletters(event.event_date)}</strong>
        </div>
        <div className="txt">
          <h4 className="title">
            <Link to={`/event-detail/${event.id}`}>
              <Text string={event.title} />
            </Link>
          </h4>
          <div className="time_date">
            <img src="images/new/calendar.svg" alt="" />
            <span>
              {helpers.eventDateFormat(event.event_date)} @
              {helpers.eventTimeFormatNew(event.time_from)}
              {", "}
              {event.time_zone}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventMiniBlk;
