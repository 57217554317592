import React, { useState } from "react";
import { Link } from "react-router-dom";
import Text from "../../common/Text";
import { getBackgroundImageUrl } from "../../../helpers/helpers";
import FormProcessingSpinner from "../../common/FormProcessingSpinner";

const Logon = ({ content, isFormProcessing, handleResetPassword }) => {
  const [showPass, setShowPass] = useState(false);
  const [formVal, setFormVal] = useState({
    password: "",
    c_password: ""
  });

  const inputHandle = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormVal({ ...formVal, [name]: value });
  };

  const passBtn = () => {
    setShowPass(!showPass);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleResetPassword(formVal);
  };
  return (
    <>
      <section
        id="logon"
        className="sm_screen_log"
      >
        <div className="contain">
              <div className="in_col">
                <form action="" method="POST" onSubmit={handleSubmit}>
                  <div className="log_blk">
                    <div className="txt">
                      <h2>
                        <Text string={content.right_heading} />
                      </h2>
                      {/* <p>
                        <Text string={content.right_tagline} />
                      </p> */}
                    </div>
                    <div className="form_row row">
                      <div className="col-sm-12">
                        <h5>
                          <Text string={content.first_field_heading} />
                        </h5>
                        <div className="form_blk pass_blk">
                          <input
                            type={!showPass ? "password" : "text"}
                            name="password"
                            id="password"
                            value={formVal.password}
                            onChange={inputHandle}
                            className="input"
                            placeholder={content.first_field_placeholder}
                          />
                          <i
                            className={
                              !showPass ? "icon-eye" : "icon-eye-slash"
                            }
                            onClick={passBtn}
                          ></i>
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <h5>
                          <Text string={content.second_field_heading} />
                        </h5>
                        <div className="form_blk pass_blk">
                          <input
                            type={!showPass ? "password" : "text"}
                            name="c_password"
                            id="c_password"
                            value={formVal.c_password}
                            onChange={inputHandle}
                            className="input"
                            placeholder={content.second_field_placeholder}
                          />
                          <i
                            className={
                              !showPass ? "icon-eye" : "icon-eye-slash"
                            }
                            onClick={passBtn}
                          ></i>
                        </div>
                      </div>
                    </div>
                    <div className="btn_blk form_btn form_blk">
                      <button
                        type="submit"
                        className="site_btn block"
                        disabled={isFormProcessing}
                      >
                        <FormProcessingSpinner
                          isFormProcessing={isFormProcessing}
                        />
                        <Text string={content.submit_text} />
                      </button>
                    </div>
                    <div className="forgot">
                      <Text string={content.forgot_password} />
                      <Link to={content.forgot_password_link}>
                        {content.forgot_password_heading}
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
        </div>
      </section>
    </>
  );
};

export default Logon;
