// const base_url = "https://ukvisa.herosolutions.com.pk/api/";
const base_url = "https://ukvisajobs.com/api/";
// const base_url = "http://localhost/work/ukvisa-new/api/";
// const base_url = "http://localhost/herosols/ukvisa/ukvisa-server/";
// const base_url = "https://ukvisajobs.com/api/";
module.exports = {
  API_BASE_URL: `${base_url}api/`,
  API_UPLOADS_URL: `${base_url}uploads/`,
  API_ASSETS_IMAGES_URL: `${base_url}assets/images/`,
  API_CMS_BG_IMAGES_URL: `${base_url}uploads/images/`
};
