import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Data from "../../dummy";
import PaginatedJobs from "./PaginatedJobs";
import MultiRangeSlider from "../multirangeslider/MultiRangeSlider";
import FormProcessingSpinner from "../../common/FormProcessingSpinner";
import SigninPopup from "../../common/SiginPopup";
import { useSelector } from "react-redux";
import SubscriptionPopup from "../../common/SubscribePopup";

const JobList = ({
  data,
  jobs,
  cities,
  types,
  cats,
  searchJobs,
  degree_req,
  isSearching,
  saveJob,
  isJobSaving,
  industries,
  isJobApplying,
  handleJobApply,
  isJobReporting,
  handleReportAnJob,
  handleShowSigninPopup,
  showSigninPopup,
  showReportPopup,
  handleToggleReportPopup,
  totalJobs,
}) => {
  let defaultTypesState = {};
  let defaultSubTypes = {};
  // cats.map((cat) => {
  //   return (defaultTypesState[cat.id] = true);
  // });

  // cats.map((cat) => {
  //   return cat.sub_cats.map((row) => {
  //     return (defaultSubTypes[row.id] = true);
  //   });
  // });
  const searchField = useRef(null);
  const navigate = useNavigate();
  const authToken = useSelector((state) => state.fetchSignin.authToken);
  const plan = useSelector((state) => state.fetchSiteSettings.plan);
  const [showSubscripitionPopup, setShowSubscriptionPopup] = useState(false);

  const { icon, icon_alt, btn, flag, flag_alt } = Data.srch_bar;
  const [searchParams] = useSearchParams();
  const { page } = useParams();
  const [active, setActive] = useState(false);
  const [sortBy, setSortBy] = useState("desc");
  const [jobCats, setJobCats] = useState({});
  const [jobRequirements, setJobRequirements] = useState({});
  const [citiesFilter, setCitiesFilter] = useState({});
  const [tpyesFilter, settypesFilter] = useState(defaultTypesState);
  const [subTypesFilter, setSubTypesFilter] = useState(defaultSubTypes);
  const [visaAcceptance, setVisaAcceptance] = useState(false);
  const [applicantsOutUk, setApplicantsOutUk] = useState(false);
  const [search, setSearch] = useState(searchParams.get("search"));
  const [searchByField, setSearchByField] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  // const [minPrice, setMinPrice] = useState(0);
  // const [maxPrice, setMaxPrice] = useState(100000);
  const [filter, setFilter] = useState(false);
  const NO_OF_BLOCKS = 4;
  const [firstJobsIndex, setFirstJobsIndex] = useState(NO_OF_BLOCKS);
  const filteredIndustries = industries.slice(0, firstJobsIndex);

  const [secondJobsIndex, setSecondJobsIndex] = useState(NO_OF_BLOCKS);
  const filteredLocations = cities.slice(0, secondJobsIndex);
  const [pageNo, setPageNo] = useState(page);

  const ToggleFilter = () => {
    setFilter(!filter);
  };
  // useEffect(() => {
  //   cats.map((cat) => {
  //     tpyesFilter[cat.id] = true;
  //   });
  // }, []);

  if (searchParams.has("search")) {
    if (!plan) {
      navigate("/subscription");
    }
  }

  const LoadMoreFirst = () => {
    setFirstJobsIndex(industries.length);
  };

  const LoadMoreSecond = () => {
    setSecondJobsIndex(cities.length);
  };

  const resetFirst = () => {
    setFirstJobsIndex(NO_OF_BLOCKS);
  };

  const resetSecond = () => {
    setSecondJobsIndex(NO_OF_BLOCKS);
  };

  const toggleFilter = () => {
    setActive(!active);
  };

  const handleJobCatsChange = (event) => {
    setIsFilterApplied(true);
    setJobCats({
      ...jobCats,
      [event.target.id]: event.target.checked,
    });
  };

  const handleDegreeRequirementChange = (event) => {
    setIsFilterApplied(true);
    setJobRequirements({
      ...jobRequirements,
      [event.target.id]: event.target.checked,
    });
  };

  const handleCityChange = (event) => {
    setIsFilterApplied(true);

    setCitiesFilter({
      ...citiesFilter,
      [event.target.id]: event.target.checked,
    });
  };

  const handleTypesChange = (event, sub_cats) => {
    // setIsFilterApplied(true);

    let typesCurrent = { ...subTypesFilter };
    sub_cats.map((row) => {
      return (typesCurrent = {
        ...typesCurrent,
        [row.id]: event.target.checked,
      });
    });

    setSubTypesFilter(typesCurrent);
    settypesFilter({
      ...tpyesFilter,
      [event.target.id]: event.target.checked,
    });
  };

  const handleSubtypesChangeChange = (event, sub_cats) => {
    // setIsFilterApplied(true);
    let mainCat = event.target.getAttribute("data-cat");
    let subCatsId = [];

    sub_cats.map((row) => {
      return subCatsId.push(parseInt(row.id));
    });

    let subTypesCurrent = {};
    subTypesCurrent = {
      ...subTypesFilter,
      [event.target.id]: event.target.checked,
    };

    if (event.target.checked) {
      let checkedSubCatsLength = 0;
      for (let prop in subTypesCurrent) {
        prop = parseInt(prop);
        if (subCatsId.includes(prop)) {
          if (subTypesCurrent[prop]) {
            checkedSubCatsLength++;
          }
        }
      }

      if (checkedSubCatsLength === sub_cats.length) {
        settypesFilter({
          ...tpyesFilter,
          [mainCat]: true,
        });
      }
    } else {
      settypesFilter({
        ...tpyesFilter,
        [mainCat]: false,
      });
    }

    setSubTypesFilter({
      ...subTypesFilter,
      [event.target.id]: event.target.checked,
    });
  };

  const handleSearchChange = (event) => {
    setIsFilterApplied(true);
    setSearch(event.target.value);
  };

  const handleSetVisaAcceptance = () => {
    setIsFilterApplied(true);
    setVisaAcceptance(!visaAcceptance);
  };

  const handleSetApplicantsOutUk = () => {
    setIsFilterApplied(true);
    setApplicantsOutUk(!applicantsOutUk);
  };

  const handleToggleSubscriptionPopup = () => {
    setShowSubscriptionPopup(!showSubscripitionPopup);
  };

  const handleSearchFromField = () => {
    if (!plan) {
      // window.location.replace("/subscription");
      navigate("/subscription");

      return false;
    }

    setIsFilterApplied(true);
    navigate("/open-jobs/1");
    setPageNo(1);
    handleSearchJobs();
  };

  // document.addEventListener("keypress", (e) => {
  //   if (e.code === "Enter") {
  //     if (document.activeElement === searchField.current) {
  //       e.preventDefault();
  //       handleSearchFromField();
  //     }
  //   }
  // });

  const handleSearchFormSubmit = (e) => {
    e.preventDefault();
    handleSearchFromField();
  };

  const handleSortByChange = (val) => {
    ToggleFilter();
    setSortBy(val);
  };

  const handleApplyFilters = () => {
    if (!plan) {
      // window.location.replace("/subscription");
      navigate("/subscription");
      return false;
    }

    navigate("/open-jobs/1");
    setPageNo(1);
    handleSearchJobs();
  };

  useEffect(() => {
    handleSearchJobs();
  }, [sortBy]);

  const handleClearAllFilter = () => {
    setSortBy("desc");
    setJobCats({});
    setJobRequirements({});
    setCitiesFilter({});
    settypesFilter({});
    setSubTypesFilter({});
    setVisaAcceptance(false);
    setApplicantsOutUk(false);
    // setSearch(searchParams.get("search"));
    setSearch(null);
    //call search
    setIsFilterApplied(false);
    handleSearchJobs();
  };

  const handleSearchJobs = () => {
    setActive(false);
    // alert();

    let jobCatsArr = [];

    for (let key in jobCats) {
      if (jobCats[key]) jobCatsArr.push(parseInt(key));
    }

    let citiesFilterArr = [];
    for (let key in citiesFilter) {
      if (citiesFilter[key]) citiesFilterArr.push(key.toString());
    }

    let tpyesFilterArr = [];
    for (let key in tpyesFilter) {
      if (tpyesFilter[key]) tpyesFilterArr.push(key.toString());
    }

    let subTypesFilterArr = [];
    for (let key in subTypesFilter) {
      if (subTypesFilter[key]) subTypesFilterArr.push(key.toString());
    }

    let degreeRequirementsArr = [];
    for (let key in jobRequirements) {
      if (jobRequirements[key]) degreeRequirementsArr.push(key.toString());
    }

    let filters = {};
    filters = {
      sortBy: sortBy,
      jobCats: jobCatsArr,
      cities: citiesFilterArr,
      types: tpyesFilterArr,
      jobRequirements: degreeRequirementsArr,
      visaAcceptance: visaAcceptance,
      applicants_outside_uk: applicantsOutUk,
      searchKeyword: search,
      subTypes: subTypesFilterArr,
      // minPrice: minPrice,
      // maxPrice: maxPrice
      pageNo: pageNo,
    };
    // console.log(filters);
    searchJobs(filters);
  };

  useEffect(() => {
    if (pageNo > 2 && !plan) navigate("/subscription");
    else handleSearchJobs();
  }, [pageNo]);

  // const handleSalaryChange = (min, max) => {
  //   setMinPrice(min);
  //   setMaxPrice(max);
  // };

  // useEffect(() => {
  //   if (search === "") {
  //     setSearchByField(!searchByField);
  //   }
  // }, [search]);

  // useEffect(() => {
  //   let jobCatsArr = [];
  //   for (let key in jobCats) {
  //     if (jobCats[key]) jobCatsArr.push(parseInt(key));
  //   }

  //   let citiesFilterArr = [];
  //   for (let key in citiesFilter) {
  //     if (citiesFilter[key]) citiesFilterArr.push(key.toString());
  //   }

  //   let tpyesFilterArr = [];
  //   for (let key in tpyesFilter) {
  //     if (tpyesFilter[key]) tpyesFilterArr.push(key.toString());
  //   }

  //   let subTypesFilterArr = [];
  //   for (let key in subTypesFilter) {
  //     if (subTypesFilter[key]) subTypesFilterArr.push(key.toString());
  //   }

  //   let degreeRequirementsArr = [];
  //   for (let key in jobRequirements) {
  //     if (jobRequirements[key]) degreeRequirementsArr.push(key.toString());
  //   }

  //   let filters = {
  //     sortBy: sortBy,
  //     jobCats: jobCatsArr,
  //     cities: citiesFilterArr,
  //     types: tpyesFilterArr,
  //     jobRequirements: degreeRequirementsArr,
  //     visaAcceptance: visaAcceptance,
  //     applicants_outside_uk: applicantsOutUk,
  //     searchKeyword: search,
  //     subTypes: subTypesFilterArr
  //     // minPrice: minPrice,
  //     // maxPrice: maxPrice
  //   };
  //   // console.log(filters);
  //   searchJobs(filters);
  // }, [
  //   sortBy,
  //   jobCats,
  //   citiesFilter,
  //   tpyesFilter,
  //   visaAcceptance,
  //   applicantsOutUk,
  //   jobRequirements,
  //   searchByField,
  //   subTypesFilter
  //   // minPrice,
  //   // maxPrice
  // ]);

  return (
    <>
      <section id="open_jobs">
        <div className="top_blk">
          <div className="contain">
            <form
              action="/open-jobs/1"
              onSubmit={handleSearchFormSubmit}
              method="POST"
              id="srch_bar"
            >
              <div className="inside input">
                {/* <div className="flag_blk d-inline-flex align-items-center">
                  UK <img src={flag} alt={flag_alt} />
                </div> */}
                <div className="form_blk">
                  <img src="/images/new/search-normal.svg" alt={icon_alt} />
                  <input
                    type="text"
                    name="search"
                    id="search"
                    value={search}
                    onChange={handleSearchChange}
                    className="input"
                    placeholder="Job, Company, Location"
                    autoComplete="off"
                    ref={searchField}
                  />
                </div>
                <button
                  type="submit"
                  className="site_btn"
                  // onClick={() => setSearchByField(!searchByField)}
                  onClick={handleSearchFromField}
                  disabled={isSearching}
                >
                  {btn}
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="jobs_page" id="job__block">
          <div className="contain">
            <div className="flex">
              <div className="filter_wrapper_col colL">
                <div id="filter" className={active ? "active" : ""}>
                  <button
                    type="button"
                    className="x_btn"
                    onClick={toggleFilter}
                  ></button>
                  <div className="top_head mb-0">
                    <h4 className="mb-0">Filter Jobs</h4>
                    <button
                      onClick={handleClearAllFilter}
                      className="clear_filter"
                      disabled={isSearching}
                    >
                      Clear All
                    </button>
                  </div>
                  <div className="in_blk">
                    <h6>Job Type</h6>
                    <ul className="ctg_lst">
                      {cats.map((cat, key) => (
                        <li key={key}>
                          <label>
                            <input
                              type="checkbox"
                              value={cat.id}
                              id={cat.id}
                              name="job_type"
                              onChange={(e) =>
                                handleTypesChange(e, cat.sub_cats)
                              }
                              checked={tpyesFilter[cat.id]}
                            />
                            {cat.title} <span>({cat.count})</span>
                          </label>
                          <ul>
                            {cat.sub_cats &&
                              cat.sub_cats.map((sub) => (
                                <li>
                                  <div className="lbl_btn">
                                    <input
                                      data-cat={cat.id}
                                      type="checkbox"
                                      value={sub.id}
                                      id={sub.id}
                                      name="job_sub"
                                      onChange={(e) =>
                                        handleSubtypesChangeChange(
                                          e,
                                          cat.sub_cats
                                        )
                                      }
                                      checked={subTypesFilter[sub.id]}
                                    />
                                    <label htmlFor="interships">
                                      {sub.title}
                                    </label>
                                  </div>
                                </li>
                              ))}
                          </ul>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="in_blk">
                    <h6>Degree Requirement</h6>
                    <ul className="ctg_lst">
                      {degree_req.map((dr, key) => (
                        <li key={key}>
                          <label>
                            <input
                              type="checkbox"
                              value={dr.id}
                              id={dr.id}
                              name="degree_req"
                              onChange={handleDegreeRequirementChange}
                              checked={jobRequirements[dr.id]}
                            />
                            {dr.title}
                            <span>({dr.count})</span>
                          </label>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="in_blk">
                    <h6>Job Industry</h6>
                    <ul className="ctg_lst">
                      {filteredIndustries.map((industry, key) => (
                        <li key={key}>
                          <label>
                            <input
                              type="checkbox"
                              value={industry.id}
                              id={industry.id}
                              name="job_cat"
                              onChange={handleJobCatsChange}
                              checked={jobCats[industry.id]}
                            />
                            {industry.title} <span>({industry.count})</span>
                          </label>
                        </li>
                      ))}
                    </ul>
                    {industries.length > NO_OF_BLOCKS && (
                      <button
                        onClick={
                          firstJobsIndex === industries.length
                            ? resetFirst
                            : LoadMoreFirst
                        }
                        className="view_more_new"
                      >
                        {firstJobsIndex === industries.length
                          ? "View Less"
                          : "View All"}
                      </button>
                    )}
                  </div>
                  <div className="in_blk">
                    <h6>Location</h6>
                    <ul className="ctg_lst">
                      {filteredLocations.map((city, key) => (
                        <li key={key}>
                          <label>
                            <input
                              type="checkbox"
                              value={city.id}
                              id={city.id}
                              name="city"
                              onChange={handleCityChange}
                              checked={citiesFilter[city.id]}
                            />
                            {city.title} <span>({city.count})</span>
                          </label>
                        </li>
                      ))}
                    </ul>
                    {cities.length > NO_OF_BLOCKS && (
                      <button
                        onClick={
                          secondJobsIndex === cities.length
                            ? resetSecond
                            : LoadMoreSecond
                        }
                        className="view_more_new"
                      >
                        {secondJobsIndex === cities.length
                          ? "View Less"
                          : "View All"}
                      </button>
                    )}
                  </div>

                  <div className="in_blk">
                    <h6>Suitable For</h6>
                    <ul className="ctg_lst">
                      <li>
                        <label>
                          <input
                            type="checkbox"
                            name="visa_acceptance"
                            onClick={handleSetVisaAcceptance}
                            checked={visaAcceptance}
                          />
                          Graduate Visa holders
                        </label>
                      </li>
                      <li>
                        <label>
                          <input
                            type="checkbox"
                            name="applicants_outside_uk"
                            onClick={handleSetApplicantsOutUk}
                            checked={applicantsOutUk}
                          />
                          Applicants based outside the UK
                        </label>
                      </li>
                    </ul>
                  </div>
                  <div className="in_blk">
                    <div className="btn_blk text-left">
                      <button
                        className="site_btn"
                        onClick={handleApplyFilters}
                        disabled={isSearching}
                      >
                        <FormProcessingSpinner isFormProcessing={isSearching} />
                        Apply Filters
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="colR">
                <div className="top_head main_filter flex">
                  <h5>
                    {totalJobs == 0
                      ? "0 record."
                      : "Showing " +
                        ((pageNo - 1) * 15 + 1) +
                        " to " +
                        ((pageNo - 1) * 15 + jobs.length) +
                        " of " +
                        totalJobs +
                        (totalJobs === 1 ? " role" : " roles")}
                  </h5>
                  <div className="inner_filter_main">
                    <span>Sort by :</span>
                    <div className="drop">
                      <div className="drop_btn" onClick={ToggleFilter}>
                        <h6>
                          {sortBy == "desc" ? "Most Recent" : "Least Recent"}
                        </h6>
                        <div className="drop_arrow">
                          <img src="/images/new/arrow-down-filter.svg" alt="" />
                        </div>
                      </div>
                      <div className={filter ? "drop_cnt active" : "drop_cnt"}>
                        <ul className="drop_lst">
                          <li
                            onClick={() => handleSortByChange("desc")}
                            style={{ cursor: "pointer" }}
                          >
                            Most Recent
                          </li>
                          <li
                            onClick={() => handleSortByChange("asc")}
                            style={{ cursor: "pointer" }}
                          >
                            Least Recent
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <PaginatedJobs
                  jobs={jobs}
                  isSearching={isSearching}
                  saveJob={saveJob}
                  isJobSaving={isJobSaving}
                  itemsPerPage={15}
                  isJobApplying
                  handleJobApply
                  isJobReporting={isJobReporting}
                  handleReportAnJob={handleReportAnJob}
                  handleShowSigninPopup={handleShowSigninPopup}
                  showSigninPopup={showSigninPopup}
                  showReportPopup={showReportPopup}
                  handleToggleReportPopup={handleToggleReportPopup}
                  setPageNo={setPageNo}
                  pageNo={pageNo}
                  totalJobs={totalJobs}
                />
              </div>
            </div>
            <div id="filter_btn_blk" className="btn_blk">
              <button
                type="button"
                id="filter_btn"
                className="site_btn auto"
                onClick={toggleFilter}
              >
                <img
                  src={process.env.PUBLIC_URL + "/images/icon-slider.svg"}
                  alt=""
                />
                View Filters
              </button>
            </div>
          </div>
        </div>
      </section>
      {showSigninPopup && (
        <SigninPopup handleShowSigninPopup={handleShowSigninPopup} />
      )}
      {showSubscripitionPopup && (
        <SubscriptionPopup
          handleToggleSubscriptionPopup={handleToggleSubscriptionPopup}
        />
      )}
    </>
  );
};

export default JobList;
