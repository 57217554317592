import React from "react";
import ImageControl from "../../common/ImageControl";
import Text from "../../common/Text";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import { websiteLink } from "../../../helpers/helpers";

function Banner({ content, candidates_images, totalJobs, totalEmployees }) {
  return (
    <>
      <section id="banner">
        <div className="contain">
          <div className="flex_blk">
            <div className="flex">
              <div className="colL">
                <div className="content">
                  <h1 className="heading">
                    <Text string={content.banner_heading} parse={true} />
                  </h1>
                  <p>
                    <Text string={content.banner_tagline} />
                  </p>
                  <div className="flex flex_count">
                    <div className="col_num">
                      <div className="_inner">
                        {/* <h3>{content.state_heading1}</h3> */}
                        <h3>
                          <CountUp
                            end={parseInt(content.state_heading1)}
                            duration={3}
                          />{" "}
                          +
                        </h3>
                        <p>{content.state_title1}</p>
                      </div>
                    </div>
                    <div className="col_num">
                      <div className="_inner">
                        {/* <h3>{totalJobs}+</h3> */}
                        <h3>
                          <CountUp end={totalJobs} duration={3} /> +
                        </h3>
                        <p>{content.state_title2}</p>
                      </div>
                    </div>
                    <div className="col_num">
                      <div className="_inner">
                        {/* <h3>{totalEmployees}+</h3> */}
                        <h3>
                          <CountUp end={totalEmployees} duration={3} /> +
                        </h3>
                        <p>{content.state_title3}</p>
                      </div>
                    </div>
                  </div>
                  <div className="btn_blk">
                    <Link
                      to={websiteLink(content.banner_button_link1)}
                      className="site_btn color_btn"
                    >
                      {content.banner_button_text1}
                    </Link>
                    <Link
                      to={websiteLink(content.banner_button_link2)}
                      className="site_btn blank"
                    >
                      {content.banner_button_text2}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="colR">
                <ImageControl folder="images" src={content.banner_image} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Banner;
