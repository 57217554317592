import React, { useEffect } from "react";
import Data from "../../dummy";
import Logon from "./Logon";
import { ToastContainer } from "react-toastify";

import {
  sendLink,
  fetchForgotPassword
} from "../../../states/actions/fetchForgotPassword";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchForgotPassword.content);
  const isLoading = useSelector((state) => state.fetchForgotPassword.isLoading);
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  const isFormProcessing = useSelector(
    (state) => state.fetchForgotPassword.isFormProcessing
  );
  const { content } = data;

  useEffect(() => {
    dispatch(fetchForgotPassword());
  }, []);

  const handleForgotPassword = (formData) => {
    dispatch(sendLink(formData));
  };

  useDocumentTitle(data.page_title);
  return (
    <>
      {isLoading || isSiteSettingsLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <ToastContainer />
          <Logon
            data={Data.signin}
            content={content}
            isFormProcessing={isFormProcessing}
            handleForgotPassword={handleForgotPassword}
          />
        </>
      )}
    </>
  );
};

export default ForgotPassword;
