import http from "../../helpers/http";
import httpBlob from "../../helpers/http-blob";
import * as helpers from "../../helpers/helpers";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import Text from "../../components/common/Text";

import {
  FETCH_TALENT_PROFILE,
  FETCH_TALENT_PROFILE_SUCCESS,
  FETCH_TALENT_PROFILE_FAILED,
  SAVE_TALENT_PROFILE,
  SAVE_TALENT_PROFILE_SUCCESS,
  SAVE_TALENT_PROFILE_FAILED
} from "./actionTypes";

export const fetchTalentProfile = () => (dispatch) => {
  dispatch({
    type: FETCH_TALENT_PROFILE,
    payload: null
  });
  http
    .post(
      "user/talent-profile",
      helpers.doObjToFormData({ token: localStorage.getItem("authToken") })
    )
    .then(({ data }) => {
      dispatch({
        type: FETCH_TALENT_PROFILE_SUCCESS,
        payload: data
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_TALENT_PROFILE_FAILED,
        payload: error
      });
      const { data } = error.response;
      localStorage.removeItem("authToken");
      window.location.reload();
    });
};

export const saveTalentProfileAction = (formData) => (dispatch) => {
  formData = { ...formData, token: localStorage.getItem("authToken") };
  let file = formData.cv;
  delete formData.cv;
  formData = helpers.doObjToFormData(formData);
  if (typeof file != "undefined") formData.append("cv", file[0]);

  dispatch({
    type: SAVE_TALENT_PROFILE,
    payload: null
  });
  httpBlob
    .post("user/save-talent-profile", formData)
    .then(({ data }) => {
      if (data.status) {
        toast.success("Talent profile saved successfully.", TOAST_SETTINGS);
        dispatch({
          type: SAVE_TALENT_PROFILE_SUCCESS,
          payload: data
        });
        // setTimeout(() => {
        //   window.location.reload();
        // }, 3000);
      } else {
        if (data.validationErrors) {
          toast.error(
            <Text string={data.validationErrors} parse={true} />,
            TOAST_SETTINGS
          );
          dispatch({
            type: SAVE_TALENT_PROFILE_FAILED,
            payload: null
          });
        }
      }
    })
    .catch((error) => {
      dispatch({
        type: SAVE_TALENT_PROFILE_FAILED,
        payload: error
      });
      const { data } = error.response;
      localStorage.removeItem("authToken");
      window.location.reload();
    });
};
