import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

const data = {
  list: [
    {
      id: 1,
      src: "/images/new/dashboard.svg",
      alt: "Dashboard",
      btn: "Dashboard",
      btn_link: "/dashboard",
      sub: []
    },
    {
      id: 2,
      src: "/images/new/user-square.svg",
      alt: "Talent Profile",
      btn: "Talent Profile",
      btn_link: "/talent-profile",
      sub: []
    }
  ],

  list2: [
    {
      id: 1,
      src: "/images/new/dollar-square.svg",
      alt: "Ambassador Programme",
      btn: "Ambassador Programme",
      btn_link: "/ambassador-programme",
      sub: []
    },
    {
      id: 2,
      src: "/images/new/dollar-square.svg",
      alt: "Subscriptions Management",
      btn: "Subscriptions Management",
      btn_link: "/dashboard-subscription",
      sub: []
    },
    {
      id: 3,
      src: "/images/new/user-square.svg",
      alt: "Profile Update",
      btn: "Profile Update",
      btn_link: "/profile-settings",
      sub: []
    }
  ]
};

const DashSidebar = ({ active, toggle }) => {
  const memData = useSelector((state) => state.fetchSiteSettings.memData);
  if (memData) {
    console.log(memData.mem_ambassador_programme == 1);
  }
  const dashboard_articles = useSelector(
    (state) => state.fetchSiteSettings.dashboard_articles
  );
  return (
    <>
      <aside id="dash_sidebar" className={active ? "active" : ""}>
        <div className="inside">
          <ul className="list">
            {data.list.map((val) => {
              return (
                <li key={val.id}>
                  <NavLink to={val.btn_link} onClick={toggle}>
                    <img src={val.src} alt={val.alt} />
                    {val.btn}
                  </NavLink>
                  <ul className="sub">
                    {val.sub.map((val) => {
                      return (
                        <li key={val.id}>
                          <NavLink to={val.btn_link}>{val.btn}</NavLink>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              );
            })}
            {dashboard_articles &&
              dashboard_articles.map((val) => {
                return (
                  <li key={val.id}>
                    <NavLink
                      to={`/article/${val.encoded_id}/${val.slug}`}
                      onClick={toggle}
                    >
                      <img
                        src="/images/new/document-text.svg"
                        alt="doucment image"
                      />
                      {val.title}
                    </NavLink>
                  </li>
                );
              })}
            {data.list2.map((val) => {
              if (memData) {
                if (val.id === 1) {
                  if (memData.mem_ambassador_programme == 0) return null;
                }
              }
              return (
                <li key={val.id}>
                  <NavLink to={val.btn_link} onClick={toggle}>
                    <img src={val.src} alt={val.alt} />
                    {val.btn}
                  </NavLink>
                  <ul className="sub">
                    {val.sub.map((val) => {
                      return (
                        <li key={val.id}>
                          <NavLink to={val.btn_link}>{val.btn}</NavLink>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              );
            })}
          </ul>
        </div>
      </aside>
    </>
  );
};

export default DashSidebar;
