import React from "react";
// import { Link } from "react-router-dom";

function SocialLinks({ siteSettings }) {
  return (
    <>
      <div className="social_logon">
        <a href={siteSettings.site_facebook} target="_blank" rel="noreferrer">
          <img src="/images/new/facebook.svg" alt="" />
        </a>
        <a href={siteSettings.site_twitter} target="_blank" rel="noreferrer">
          <img src="/images/new/twitter.svg" alt="" />
        </a>
        <a href={siteSettings.site_linkedin} target="_blank" rel="noreferrer">
          <img src="/images/new/linkedin.svg" alt="" />
        </a>
      </div>
    </>
  );
}

export default SocialLinks;
