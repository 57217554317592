import React, { useEffect } from "react";
import Data from "../../dummy";
import Cover from "../../common/Cover";
import Accordion from "./Accordion";

import { fetchFaq } from "../../../states/actions/fetchFaq";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";
import useReferralLink from "../../../hooks/useReferralLink";
import { useSearchParams } from "react-router-dom";

const Faq = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const referralLink = useReferralLink(
    searchParams.get("ref"),
    searchParams.get("compaign_name")
  );
  const { cover } = Data.faq;
  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchFaq.content);
  const isLoading = useSelector((state) => state.fetchFaq.isLoading);
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  const { content, faqs, faq2s } = data;

  useEffect(() => {
    dispatch(fetchFaq({ ref: referralLink }));
  }, []);

  useDocumentTitle(data.page_title);
  return (
    <>
      {isLoading || isSiteSettingsLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <Cover data={cover} content={content} />
          <Accordion content={content} faqs={faqs} faq2s={faq2s} />
        </>
      )}
    </>
  );
};

export default Faq;
