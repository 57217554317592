import { isContentEditable } from "@testing-library/user-event/dist/utils";
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import Text from "../../common/Text";
import { useForm } from "react-hook-form";
import FormProcessingSpinner from "../../common/FormProcessingSpinner";
import Multiselect from "multiselect-react-dropdown";
import { useEffect } from "react";

const TalentForm = ({
  content,
  current_status,
  work_experience,
  job_fields,
  mem,
  saveTalentProfile,
  isFormProcessing,
  work_areas
}) => {
  const cvRef = useRef();
  const [popupText, setPopupText] = useState(false);
  const PopupTextActive = () => {
    setPopupText(!popupText);
  };

  const [areasOfWorkError, setAreasOfWorkError] = useState(false);
  const [cv, setCv] = useState(null);

  let arr = [];
  if (mem != null) {
    mem?.areas.map((row) => {
      return arr.push(row.id);
    });
  }

  const [areasOfWork, setAreasOfWork] = useState(arr);
  const [defaultFieldOfExperience, setDefaultFieldOfExperience] = useState(
    mem?.field_of_most_experience
  );

  const {
    register,
    watch,
    formState: { errors },
    handleSubmit
  } = useForm();

  const onSubmit = (data) => {
    if (cv !== null) data.cv = cv.target.files;
    if (areasOfWork.length === 0) {
      setAreasOfWorkError(true);
      return false;
    } else {
      setAreasOfWorkError(false);
    }
    data.areas_of_work = areasOfWork;
    saveTalentProfile(data);
  };

  const handleCvClick = (e) => {
    e.preventDefault();
    setCv(null);
    cvRef.current.click();
  };

  const handleSelectFile = (e) => {
    setCv(e);
  };

  const onAreaOfWorkSelect = (selectedList, selectedItem) => {
    let arr = [];
    selectedList.map((row) => {
      return arr.push(row.id);
    });
    setAreasOfWork(arr);
  };

  const onAreaOfWorkRemove = (selectedList, removedItem) => {
    let arr = [];
    selectedList.map((row) => {
      return arr.push(row.id);
    });
    setAreasOfWork(arr);
  };

  const handleDefaultFieldOfExperience = (e) => {
    setDefaultFieldOfExperience(e.target.value);
  };

  // useEffect(() => {
  //   console.log(defaultFieldOfExperience);
  //   console.log(watch("field_of_most_experience"));
  // }, [defaultFieldOfExperience]);
  return (
    <>
      <div className="talent_form">
        <div className="talent_top_bar">
          <h4 className="heading">
            <Text string={content.banner_heading} />
          </h4>
          <h4 className="color heading" onClick={() => PopupTextActive()}>
            <Text string={content.popup_link_heading} />
          </h4>
          <h4 className="heading">
            <Text string={content.update_profile_heading} />
          </h4>
        </div>
        <form action="" method="POST" onSubmit={handleSubmit(onSubmit)}>
          <div className="form_row row">
            <div className="col-sm-6">
              <h5>Current status</h5>
              <select
                defaultValue={mem?.current_status}
                className="input"
                {...register("current_status", {
                  required: "Current status is required."
                })}
              >
                <option value="">Select</option>
                {current_status &&
                  current_status.map((row) => (
                    <option value={row.id}>{row.text}</option>
                  ))}
              </select>
              <span className="validation-error">
                {errors.current_status?.message}
              </span>
            </div>
            <div className="col-sm-6">
              <h5>Work experience</h5>
              <select
                defaultValue={mem?.work_experience}
                className="input"
                {...register("work_experience", {
                  required: "Work Experience is required."
                })}
              >
                <option value="">Select</option>
                {work_experience &&
                  work_experience.map((row) => (
                    <option value={row.id}>{row.text}</option>
                  ))}
              </select>
              <span className="validation-error">
                {errors.work_experience?.message}
              </span>
            </div>
            <div className="col-sm-12">
              <h5>In which field did you gain most of your work experience?</h5>
              <select
                defaultValue={mem?.field_of_most_experience}
                className="input"
                {...register("field_of_most_experience", {
                  required: "This field is required."
                })}
                onChange={handleDefaultFieldOfExperience}
              >
                <option value="">Select</option>
                {job_fields &&
                  job_fields.map((row) => (
                    <option value={row.id}>{row.text}</option>
                  ))}
              </select>
              <span className="validation-error">
                {errors.field_of_most_experience?.message}
              </span>
              {defaultFieldOfExperience == 32 && (
                <>
                  <br />
                  <input
                    defaultValue={mem?.field_of_most_experience_other}
                    type="text"
                    className="input"
                    placeholder="If other, please provide details"
                    {...register("field_of_most_experience_other", {
                      required:
                        watch("field_of_most_experience") == 32 &&
                        watch("field_of_most_experience_other") == ""
                          ? "Please provide details"
                          : false
                    })}
                  />
                  <span className="validation-error">
                    {errors.field_of_most_experience_other?.message}
                  </span>
                </>
              )}
            </div>
            <div className="col-sm-12">
              <h5>In which area(s) do you want to work in?</h5>
              {/*<select
                defaultValue={mem?.area_of_work}
                className="input"
                {...register("area_of_work", {
                  required: "This field is required.",
                })}
              >
                <option value="">Select</option>
                {job_fields &&
                  job_fields.map((row) => (
                    <option value={row.id}>{row.text}</option>
                  ))}
                  </select> */}
              <Multiselect
                options={work_areas}
                selectedValues={mem?.areas}
                onSelect={onAreaOfWorkSelect}
                onRemove={onAreaOfWorkRemove}
                displayValue="text"
                showCheckbox={true}
              />
              {areasOfWorkError && (
                <span className="validation-error">
                  Please select area(s) of work.
                </span>
              )}
            </div>
            <div className="col-sm-12">
              <h5>
                Do you hold any professional qualification? - please provide
                details.
              </h5>
              <textarea
                defaultValue={mem?.professional_qualification}
                className="input"
                {...register("professional_qualification", {
                  required: "This field is required."
                })}
              ></textarea>
              <span className="validation-error">
                {errors.professional_qualification?.message}
              </span>
            </div>
            <div className="col-sm-12">
              <h5>Please upload your CV </h5>
              <div className="upload_blk" onClick={handleCvClick}>
                <div className="upload_lbl flex">
                  <img src="images/new/gray_upload.svg" alt="" />
                  <span>
                    {cv ? cv?.target?.files[0]?.name : "Upload your CV"}
                  </span>
                </div>
              </div>
              <input
                type="file"
                ref={cvRef}
                onChange={handleSelectFile}
                hidden
              />
            </div>
            {mem === null && (
              <div className="col-sm-12">
                <div className="lbl_btn">
                  <input
                    type="checkbox"
                    id="confirm"
                    {...register("confirm", {
                      required: "Please accept terms and conditions."
                    })}
                  />
                  <label htmlFor="confirm">
                    By submitting this talent profile, I agree to the
                    <Link target="_blank" to="/terms-conditions">
                      &nbsp;T&C&nbsp;
                    </Link>
                    and &nbsp;
                    <Link target="_blank" to="/privacy-policy">
                      Privacy Policy.
                    </Link>{" "}
                    of the website.
                  </label>
                </div>
                <span className="validation-error">
                  {errors.confirm?.message}
                </span>
              </div>
            )}
          </div>
          <div className="br"></div>
          <div className="site_blk">
            <button className="site_btn" disabled={isFormProcessing}>
              <FormProcessingSpinner isFormProcessing={isFormProcessing} />
              Update Infomation
            </button>
          </div>
        </form>
      </div>

      <div
        className={
          popupText ? "popup lg text_popup active" : "popup lg text_popup"
        }
      >
        <div className="table_dv">
          <div className="table_cell">
            <div className="contain">
              <div className="_inner">
                <button
                  type="button"
                  className="x_btn"
                  onClick={() => PopupTextActive()}
                ></button>
                <div className="text-center heading_text">
                  <h2>
                    <Text string={content.popup_heading} />
                  </h2>
                </div>
                <div className="ckEditor text_popup">
                  <Text string={content.popup_detail} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TalentForm;
