import React, { useEffect } from "react";
import SubscriptionManage from "./Subscription-Manage";
import PaymentHistory from "./Payment-History";

import { fetchDashSubscription } from "../../../states/actions/fetchDashSubscription";
import { useSelector, useDispatch } from "react-redux";
import LoadingScreen from "../../common/LoadingScreen";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import { ToastContainer } from "react-toastify";

const DashSubscription = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchDashSubscription.content);
  const isLoading = useSelector(
    (state) => state.fetchDashSubscription.isLoading
  );
  const subscribeArr = useSelector(
    (state) => state.fetchDashSubscription.subscribeArr
  );
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  const isFormProcessing = useSelector(
    (state) => state.fetchDashSubscription.isFormProcessing
  );
  const mem = useSelector((state) => state.fetchDashSubscription.mem);
  const { content, plan, payment_history } = data;

  useEffect(() => {
    dispatch(fetchDashSubscription());
  }, []);

  // const saveTalentProfile = (formData) => {
  //   dispatch(saveTalentProfileAction(formData));
  // };

  useDocumentTitle(data.page_title);
  return (
    <>
      {isLoading || isSiteSettingsLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <ToastContainer />
          <section id="dash">
            <div className="contain-fluid">
              <SubscriptionManage
                content={content}
                plan={plan}
                subscribeArr={subscribeArr}
              />
              <div className="gap_mid"></div>
              <PaymentHistory payment_history={payment_history} />
            </div>
          </section>
        </>
      )}
    </>
  );
};
export default DashSubscription;
