import React, { useEffect } from "react";
import Bar from "./Bar";
import Overview from "./Overview";
import LinkGenrator from "./Link-genrator";

import { fetchAmbassadorProgramme } from "../../../states/actions/fetchAmbassadorProgramme";
import { useSelector, useDispatch } from "react-redux";
import LoadingScreen from "../../common/LoadingScreen";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import { ToastContainer } from "react-toastify";

const AmbassadorProgramme = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchAmbassadorProgramme.content);
  const isLoading = useSelector(
    (state) => state.fetchAmbassadorProgramme.isLoading
  );
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );

  const { content, pages, payouts_history } = data;

  useEffect(() => {
    dispatch(fetchAmbassadorProgramme());
  }, []);

  useDocumentTitle(data.page_title);
  return (
    <>
      {isLoading || isSiteSettingsLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <ToastContainer />
          <section id="dash">
            <div className="contain-fluid">
              <Bar data={data} />
              <Overview data={data} />
              <div className="br"></div>
              <LinkGenrator
                content={content}
                pages={pages}
                payouts_history={payouts_history}
              />
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default AmbassadorProgramme;
