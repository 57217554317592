import React, { useEffect } from "react";
import Detail from "./Detail";

import { fetchArticle } from "../../../states/actions/fetchArticle";
import { useSelector, useDispatch } from "react-redux";
import LoadingScreen from "../../common/LoadingScreen";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import { ToastContainer } from "react-toastify";
import { useParams } from "react-router-dom";

const Article = () => {
  const { id, slug } = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchArticle.content);
  const isLoading = useSelector((state) => state.fetchArticle.isLoading);
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );

  const { article } = data;
  useEffect(() => {
    dispatch(fetchArticle(id));
  }, [id]);

  useDocumentTitle(data.page_title);
  return (
    <>
      {isLoading || isSiteSettingsLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <ToastContainer />
          <section id="dash">
            <div className="contain-fluid">
              <Detail article={article} />
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default Article;
