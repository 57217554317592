import React, { useEffect } from "react";
import Text from "../../common/Text";
import { priceFormat } from "../../../helpers/helpers";
const Overview = ({ data }) => {
  const {
    content,
    total_clicks,
    total_referrals,
    total_subscriptions,
    total_earnings,
    total_remaining_payouts,
    total_requested_payouts
  } = data;
  return (
    <>
      <div className="_overview">
        <h4 className="heading">
          <Text string={content.stats_heading} />
        </h4>
        <div className="flex">
          <div className="ov_col">
            <div className="inner _pnk">
              <h3>{total_clicks}</h3>
              <p>
                <Text string={content.stat_card_heading1} />
              </p>
            </div>
          </div>
          <div className="ov_col">
            <div className="inner _prpl">
              <h3>{total_referrals}</h3>
              <p>
                <Text string={content.stat_card_heading2} />
              </p>
            </div>
          </div>
          <div className="ov_col">
            <div className="inner _gry">
              <h3>{total_subscriptions}</h3>
              <p>
                <Text string={content.stat_card_heading3} />
              </p>
            </div>
          </div>
          <div className="ov_col">
            <div className="inner _blu">
              <h3>£{priceFormat(total_earnings)}</h3>
              <p>
                <Text string={content.stat_card_heading4} />
              </p>
            </div>
          </div>
          <div className="ov_col">
            <div className="inner _prpl">
              <h3>£{priceFormat(total_requested_payouts)}</h3>
              <p>Requested payout</p>
            </div>
          </div>
          <div className="ov_col">
            <div className="inner _pnk">
              <h3>£{priceFormat(total_remaining_payouts)}</h3>
              <p>
                <Text string={content.stat_card_heading5} />
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
