import React, { useState } from "react";
import FormProcessingSpinner from "../common/FormProcessingSpinner";
import { useForm } from "react-hook-form";

function ReportPopup({
  handleToggleReportPopup,
  isJobReporting,
  handleReportAnJob,
  job
}) {
  const [reportPopUp, setReportPopup] = useState(false);
  const handleReportPopUp = () => {};
  const CANCEL_PLAN_REASONS = [
    "This job is no longer available",
    "This role does not sponsor a work visa",
    "This is a wrong link",
    "Other"
  ];

  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
    getValues
  } = useForm();

  const firstSubmit = (data) => {
    data = { ...data, jobId: job.id };
    handleReportAnJob(data);
  };

  return (
    <>
      <div className="popup text_popup active">
        <div className="table_dv">
          <div className="table_cell">
            <div className="contain">
              <div className="_inner">
                <button
                  type="button"
                  className="x_btn"
                  onClick={handleToggleReportPopup}
                ></button>
                <div className="heading_text">
                  <h4>Please choose the reason of report.</h4>
                </div>
                <form
                  action=""
                  method="POST"
                  onSubmit={handleSubmit(firstSubmit)}
                >
                  <div className="form_row row">
                    {CANCEL_PLAN_REASONS &&
                      CANCEL_PLAN_REASONS.map((value, index) => (
                        <div className="col-sm-12">
                          <div className="lbl_btn">
                            <input
                              type="radio"
                              {...register("reason", {
                                required: "Please select a reason."
                              })}
                              value={value}
                            />
                            <label htmlFor="">{value}</label>
                          </div>
                        </div>
                      ))}
                  </div>
                  <span className="validation-error">
                    {errors.reason?.message}
                  </span>
                  {(watch("reason") == "Other" ||
                    watch("reason") == "other") && (
                    <>
                      <span>Please state</span>
                      <input
                        type="text"
                        className="input"
                        {...register("reason_other", {
                          required: watch("reason")
                            ? "Please state the reason"
                            : false
                        })}
                      />
                      <span className="validation-error">
                        {errors.reason_other?.message}
                      </span>
                    </>
                  )}
                  <div className="br"></div>
                  <div className="btn_blk text-center">
                    <button
                      className="site_btn"
                      type="submit"
                      disabled={isJobReporting}
                    >
                      Submit
                      <FormProcessingSpinner
                        isFormProcessing={isJobReporting}
                      />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReportPopup;
