import React, { useEffect } from "react";
import Data from "../../dummy";
import Logon from "./Logon";
import { ToastContainer } from "react-toastify";

import {
  setPassword,
  fetchResetPassword
} from "../../../states/actions/fetchResetPassword";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";
import { useParams } from "react-router-dom";

const ResetPassword = () => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchResetPassword.content);
  const isLoading = useSelector((state) => state.fetchResetPassword.isLoading);
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  const isFormProcessing = useSelector(
    (state) => state.fetchResetPassword.isFormProcessing
  );
  const { content } = data;

  useEffect(() => {
    dispatch(fetchResetPassword());
  }, []);

  const handleResetPassword = (formData) => {
    formData = { ...formData, token: token };
    dispatch(setPassword(formData));
  };

  useDocumentTitle(data.page_title);
  return (
    <>
      {isLoading || isSiteSettingsLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <ToastContainer />
          <Logon
            data={Data.signin}
            content={content}
            isFormProcessing={isFormProcessing}
            handleResetPassword={handleResetPassword}
          />
        </>
      )}
    </>
  );
};

export default ResetPassword;
