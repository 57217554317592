import React, { useEffect } from "react";
import Data from "../../dummy";
import Account from "./Account";

import { fetchDashboard } from "../../../states/actions/fetchDashboard";
import { useSelector, useDispatch } from "react-redux";
import LoadingScreen from "../../common/LoadingScreen";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import { ToastContainer } from "react-toastify";

const Dashboard = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchDashboard.content);
  const isLoading = useSelector((state) => state.fetchDashboard.isLoading);
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  const applied_jobs = useSelector(
    (state) => state.fetchDashboard.applied_jobs
  );
  const saved_jobs = useSelector((state) => state.fetchDashboard.saved_jobs);
  useEffect(() => {
    dispatch(fetchDashboard());
  }, []);

  const { events, applied_job_actions } = data;

  useDocumentTitle(data.page_title);
  return (
    <>
      {isLoading || isSiteSettingsLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <ToastContainer />
          <Account
            data={Data.dashboard}
            events={events}
            saved_jobs={saved_jobs}
            applied_jobs={applied_jobs}
            applied_job_actions={applied_job_actions}
          />
        </>
      )}
    </>
  );
};

export default Dashboard;
