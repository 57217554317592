import http from "../../helpers/http";
import httpBlob from "../../helpers/http-blob";
import * as helpers from "../../helpers/helpers";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import Text from "../../components/common/Text";

import {
  FETCH_DASHBOARD_SUBSCRIPTION,
  FETCH_DASHBOARD_SUBSCRIPTION_SUCCESS,
  FETCH_DASHBOARD_SUBSCRIPTION_FAILED,
  CANCEL_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_FAILED,
} from "./actionTypes";

export const fetchDashSubscription = () => (dispatch) => {
  dispatch({
    type: FETCH_DASHBOARD_SUBSCRIPTION,
    payload: null,
  });
  http
    .post(
      "user/dashboard-subscription",
      helpers.doObjToFormData({ token: localStorage.getItem("authToken") })
    )
    .then(({ data }) => {
      dispatch({
        type: FETCH_DASHBOARD_SUBSCRIPTION_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_DASHBOARD_SUBSCRIPTION_FAILED,
        payload: error,
      });
      const { data } = error.response;
      localStorage.removeItem("authToken");
      window.location.reload();
    });
};

export const cancelSubscriptionPlan = (formData) => (dispatch) => {
  formData = { ...formData, token: localStorage.getItem("authToken") };
  formData = helpers.doObjToFormData(formData);
  dispatch({
    type: CANCEL_SUBSCRIPTION,
    payload: null,
  });
  http
    .post("user/cancel-subscription-plan", formData)
    .then(({ data }) => {
      if (data.status) {
        toast.success("Plan cancelled successfully.", TOAST_SETTINGS);
        dispatch({
          type: CANCEL_SUBSCRIPTION_SUCCESS,
          payload: data,
        });
        // setTimeout(() => {
        //   window.location.reload();
        // }, 3000);
      } else {
        if (data.validationErrors) {
          toast.error(
            <Text string={data.validationErrors} parse={true} />,
            TOAST_SETTINGS
          );
          dispatch({
            type: CANCEL_SUBSCRIPTION_FAILED,
            payload: null,
          });
        }
      }
    })
    .catch((error) => {
      dispatch({
        type: CANCEL_SUBSCRIPTION_FAILED,
        payload: error,
      });
      const { data } = error.response;
      localStorage.removeItem("authToken");
      window.location.reload();
    });
};
