import React, { useEffect, useRef, useState, useCallback } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Text from "../../common/Text";
import { useDispatch, useSelector } from "react-redux";
import ImageControl from "../../common/ImageControl";
import FormProcessingSpinner from "../../common/FormProcessingSpinner";
import { useForm } from "react-hook-form";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { verifyEmail } from "../../../states/actions/fetchSignup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleLeft,
  faArrowLeft
} from "@fortawesome/free-solid-svg-icons";
import ReCAPTCHA from "react-google-recaptcha";
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider
} from "react-google-recaptcha-v3";
import { backToSignup } from "../../../states/actions/fetchSignup";
import { websiteLink } from "../../../helpers/helpers";

const Register = ({
  data,
  content,
  handleSubmitAction,
  isFormProcessing,
  signupRef
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const recaptchaRef = useRef();
  const [recaptchaError, setRecaptchaError] = useState("");
  const [token, setToken] = useState();
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

  const siteSettings = useSelector(
    (state) => state.fetchSiteSettings.siteSettings
  );
  const isFirstStepCompleted = useSelector(
    (state) => state.fetchSignup.isFirstStepCompleted
  );
  let firstSession = localStorage.getItem("isFirstStepCompleted");
  const verificationSubmitButtonRef = useRef(null);
  const {
    register,
    watch,
    formState: { errors },
    handleSubmit
  } = useForm();

  const {
    register: register2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2
  } = useForm();

  const secondSubmit = (data, e) => {
    e.preventDefault();
    data = { ...data, email: localStorage.getItem("email") };
    data = { ...data, signupRef: signupRef };
    dispatch(verifyEmail(data));
  };

  const firstSubmit = (data, e) => {
    e.preventDefault();
    data = { ...data, recaptcha_token: token };
    handleSubmitAction(data);
  };

  const onVerify = useCallback((token) => {
    setToken(token);
  }, []);

  const handleBackToPreviousForm = (e) => {
    e.preventDefault();
    localStorage.removeItem("isFirstStepCompleted");
    localStorage.removeItem("email");

    window.location.href = websiteLink("/signup");
  };

  return (
    <>
      <section id="logon" className="register_form">
        <div className="contain">
          <div className="flex_row main_row row mobile_res_po">
            <div className="col col-lg-5">
              {(firstSession || isFirstStepCompleted) && (
                <div className="in_col">
                  <form
                    action=""
                    method="POST"
                    onSubmit={handleSubmit2(secondSubmit)}
                  >
                    <div className="log_blk">
                      <button
                        type="button"
                        onClick={handleBackToPreviousForm}
                        className="back_btn_btn"
                      >
                        <FontAwesomeIcon icon={faArrowLeft} /> Back to signup
                      </button>
                      <div className="txt heading_cstm_cstm">
                        <h2>Verify Email</h2>
                      </div>
                      <div className="form_row row">
                        <div className="col-sm-12">
                          <h5>Enter Verification Code</h5>
                          <input
                            type="number"
                            id="code"
                            className="input"
                            {...register2("code", {
                              required: "Six digit code is required."
                            })}
                          />
                          <span className="validation-error">
                            {errors2.code?.message}
                          </span>
                        </div>
                      </div>
                      <div className="btn_blk form_btn form_blk">
                        <button
                          type="submit"
                          className="site_btn"
                          disabled={isFormProcessing}
                        >
                          <FormProcessingSpinner
                            isFormProcessing={isFormProcessing}
                          />
                          Verify
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              )}
              {!firstSession && !isFirstStepCompleted && (
                <div className="in_col">
                  <form
                    action=""
                    method="POST"
                    onSubmit={handleSubmit(firstSubmit)}
                  >
                    <GoogleReCaptchaProvider reCaptchaKey="6LeTTnQdAAAAAAK2AN5RRHWx9VBAUxsLCLTLej2U">
                      <div className="log_blk">
                        <div className="txt">
                          <h2>
                            <Text string={content.banner_heading} />
                          </h2>
                        </div>
                        <div className="form_row row">
                          <div className="col-sm-6">
                            <h5>
                              <Text string={content.first_field_heading} />
                            </h5>
                            <input
                              type="text"
                              id="fname"
                              className="input"
                              {...register("fname", {
                                required: "First Name is required.",
                                minLength: {
                                  value: 2,
                                  message:
                                    "First Name should contains atleast 2 letters."
                                }
                              })}
                            />
                            <span className="validation-error">
                              {errors.fname?.message}
                            </span>
                          </div>
                          <div className="col-sm-6">
                            <h5>
                              <Text string={content.second_field_heading} />
                            </h5>
                            <input
                              type="text"
                              id="lanme"
                              className="input"
                              {...register("lname", {
                                required: "Last Name is required.",
                                minLength: {
                                  value: 2,
                                  message:
                                    "Last Name should contains atleast 2 letters."
                                }
                              })}
                            />
                            <span className="validation-error">
                              {errors.lname?.message}
                            </span>
                          </div>
                          <div className="col-sm-6">
                            <h5>
                              <Text string={content.third_field_heading} />
                            </h5>
                            <input
                              type="text"
                              id="email"
                              className="input"
                              {...register("email", {
                                required: "Email is required.",
                                pattern: {
                                  value:
                                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                  message: "Please enter a valid email"
                                }
                              })}
                            />
                            <span className="validation-error">
                              {errors.email?.message}
                            </span>
                          </div>
                          <div className="col-sm-6">
                            <h5>
                              Phone number{" "}
                              <span className="dim_span">(optional)</span>
                            </h5>
                            <PhoneInput
                              defaultCountry="GB"
                              className="input"
                              {...register("phone")}
                            />
                          </div>
                          <div className="col-sm-6">
                            <h5>
                              <Text string={content.four_field_heading} />
                            </h5>
                            <input
                              type="password"
                              id="password"
                              className="input"
                              {...register("password", {
                                required: "Password is required.",
                                minLength: {
                                  value: 6,
                                  message:
                                    "Password should be atleast 6 characters long."
                                }
                              })}
                            />
                            <span className="validation-error">
                              {errors.password?.message}
                            </span>
                          </div>
                          <div className="col-sm-6">
                            <h5>
                              <Text string={content.five_field_heading} />
                            </h5>
                            <input
                              type="password"
                              id="c_password"
                              className="input"
                              {...register("c_password", {
                                required: "Confirm Password is required.",
                                validate: (val) => {
                                  if (watch("password") != val) {
                                    return "Your passwords do no match.";
                                  }
                                },
                                minLength: {
                                  value: 6,
                                  message:
                                    "Password should be atleast 6 characters long."
                                }
                              })}
                            />
                            <span className="validation-error">
                              {errors.c_password?.message}
                            </span>
                          </div>
                          <div className="col-sm-12">
                            <div className="form_blk">
                              <div className="lbl_btn">
                                <GoogleReCaptcha
                                  onVerify={onVerify}
                                  refreshReCaptcha={refreshReCaptcha}
                                />
                              </div>
                              {recaptchaError && (
                                <span className="validation-error">
                                  {recaptchaError}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-sm-12">
                            <div className="form_blk">
                              <div className="lbl_btn">
                                <input
                                  type="checkbox"
                                  id="confirm"
                                  {...register("confirm", {
                                    required:
                                      "Please accept terms and conditions."
                                  })}
                                />
                                <label htmlFor="confirm">
                                  By ticking the checkbox, I accept the
                                  <Link target="_blank" to="/terms-conditions">
                                    &nbsp;T&C&nbsp;
                                  </Link>
                                  and &nbsp;
                                  <Link target="_blank" to="/privacy-policy">
                                    Privacy Policy.
                                  </Link>
                                </label>
                              </div>
                              <span className="validation-error">
                                {errors.confirm?.message}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="btn_blk form_btn form_blk">
                          <button
                            type="submit"
                            className="site_btn"
                            disabled={isFormProcessing}
                          >
                            <FormProcessingSpinner
                              isFormProcessing={isFormProcessing}
                            />
                            <Text string={content.submit_text} />
                          </button>
                        </div>

                        <div className="account mt-2">
                          <Text string={content.account_heading} />
                          <Link
                            to={content.account_heading_link}
                            className="color"
                          >
                            <Text string={content.account_heading_heading} />
                          </Link>
                        </div>
                      </div>
                    </GoogleReCaptchaProvider>
                  </form>
                </div>
              )}
            </div>
            <div className="col col-lg-5">
              <div className="content text-center">
                <h2 className="heading">
                  <Text string={content.right_heading} />
                </h2>
                <p>
                  <Text string={content.right_text} />
                </p>
                <div className="register_img">
                  {/* <img src="images/new/register.png" alt="" /> */}
                  <ImageControl folder="images" src={content.image1} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Register;
