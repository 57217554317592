import React, { useState } from "react";
import PopupRegister from "../../common/PopupRegister";
// import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RegisterEvent } from "../../../states/actions/fetchEventDetail";
import FormProcessingSpinner from "../../common/FormProcessingSpinner";
import SinginPopup from "../../common/SiginPopup";
import SubscriptionPopup from "../../common/SubscribePopup";

const RegisterBlk = ({
  title,
  subtitle,
  para,
  price,
  price_limit,
  btn,
  btn_link,
  id,
  showSigninPopup,
  handleShowSigninPopup
}) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    PopupRegister: false
  });
  const [showSubscripitionPopup, setShowSubscriptionPopup] = useState(false);

  const authToken = useSelector((state) => state.fetchSignin.authToken);
  const plan = useSelector((state) => state.fetchSiteSettings.plan);

  const isFormProcessing = useSelector(
    (state) => state.fetchEventDetail.isFormProcessing
  );
  const isRegistered = useSelector(
    (state) => state.fetchEventDetail.isRegistered
  );

  const showPopupRegister = () => {
    // console.log("ok");
    setState({ ...state, PopupRegister: true });
  };
  const closePopupRegister = () => {
    setState({ ...state, PopupRegister: false });
  };

  const handleToggleSubscriptionPopup = () => {
    setShowSubscriptionPopup(!showSubscripitionPopup);
  };

  const handleRegisterEvent = () => {
    if (plan?.id == 2 || plan?.id == 3) {
      // showPopupRegister();
      dispatch(RegisterEvent({ id }));
    } else {
      window.location.replace("/subscription");
    }
  };

  return (
    <>
      <div className="reg_blk">
        <h3>{isRegistered ? "Registered" : "Register Now"}</h3>
        <div className="data flex flex-nowrap justify-content-between">
          <div className="txt">
            <h5 className="color mb-0">
              {isRegistered
                ? "You have registered for this event already."
                : "To register for this event, please click Register"}
            </h5>
          </div>
        </div>
        {!isRegistered && (
          <div className="btn_blk text-right">
            <button
              type="button"
              className="site_btn long"
              onClick={handleRegisterEvent}
              disabled={isFormProcessing}
            >
              <FormProcessingSpinner isFormProcessing={isFormProcessing} />
              Register
            </button>
          </div>
        )}
      </div>

      {state.PopupRegister && !isRegistered && (
        <PopupRegister close={closePopupRegister} id={id} />
      )}
      {showSubscripitionPopup && (
        <SubscriptionPopup
          handleToggleSubscriptionPopup={handleToggleSubscriptionPopup}
        />
      )}
    </>
  );
};

export default RegisterBlk;
